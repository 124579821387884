import React, { useEffect, useMemo } from "react";
import uniqueId from "lodash/uniqueId";
import { Divider, Popup, Ref, Segment } from "semantic-ui-react";
import { fixLongName } from "../../products/copy-assistant/utils";
import { Text } from "../../components/Text";
import { useGetPreviousMachineTranslations } from "../customHooks";
import { formatDate } from "./PreviouslyMachineTranslatedRail";
import { TranslationInfo } from "./PreviouslyMachineTranslatedRailList";
import { popupDelay } from "../../customers/gpt/types";
import { Icon } from "../../components/tailwind";

type Props = {
  header?: string;
  start: Date;
  end: Date;
  setNoReturnCount: () => void;
  incrementInFlightRequest: () => void;
  decrementInFlightRequest: () => void;
  observerLastElementInList: (element: Element) => void;
  setIsLoading: () => void;
  isLastAdded: boolean;
  handleUpdateDates: (
    date: Date,
    success: boolean,
    doneFetching: boolean
  ) => void;
  loadPreviousTranslation: (info: TranslationInfo) => void;
  refreshTodaysTranslations?: boolean;
  setRefreshTodaysTranslations?: (refresh: boolean) => void;
  searchPrevTranslations: string;
  handleIncrementHiddenDates: (date: Date) => void;
  handleDecrementHiddenDates: (date: Date) => void;
  type?: "text" | "file";
};

export const PreviouslyMachineTranslatedRailListBundledDate: React.FC<Props> = ({
  header,
  start,
  end,
  setNoReturnCount,
  incrementInFlightRequest,
  decrementInFlightRequest,
  observerLastElementInList,
  setIsLoading,
  isLastAdded,
  handleUpdateDates,
  loadPreviousTranslation,
  refreshTodaysTranslations,
  setRefreshTodaysTranslations,
  searchPrevTranslations,
  handleIncrementHiddenDates,
  handleDecrementHiddenDates,
  type = "text",
}) => {
  const {
    data: translationInfo,
    isFetching,
    reFetch,
  } = useGetPreviousMachineTranslations(
    formatDate(start),
    formatDate(end),
    type
  );

  const filteredTranslationInfo = useMemo(() => {
    if (!searchPrevTranslations) return translationInfo;
    return translationInfo.filter(
      ({ source_text }) =>
        source_text
          .toLowerCase()
          .search(searchPrevTranslations.toLowerCase()) != -1
    );
  }, [searchPrevTranslations, translationInfo]);

  const noTranslationInfo = useMemo(() => {
    if (isFetching) return false;
    return !translationInfo.length;
  }, [isFetching]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (isFetching) {
        incrementInFlightRequest();
        setIsLoading();
      } else {
        decrementInFlightRequest();
      }
    }
    return () => {
      mounted = false;
    };
  }, [isFetching]);

  useEffect(() => {
    if (!noTranslationInfo && !isFetching) {
      if (!filteredTranslationInfo.length) {
        handleIncrementHiddenDates(start);
      } else {
        handleDecrementHiddenDates(start);
      }
    }
  }, [filteredTranslationInfo, noTranslationInfo, isFetching]);

  useEffect(() => {
    if (refreshTodaysTranslations) {
      reFetch();
      setRefreshTodaysTranslations?.(false);
    }
  }, [refreshTodaysTranslations]);

  useEffect(() => {
    let mounted = true;
    if (!mounted) return;
    handleUpdateDates(start, !noTranslationInfo, !isFetching);
    if (noTranslationInfo) {
      setNoReturnCount();
    }
    return () => {
      mounted = false;
    };
  }, [noTranslationInfo, isFetching]);

  if (noTranslationInfo || isFetching || !filteredTranslationInfo.length)
    return <></>;

  return (
    <>
      {!!header && (
        <>
          <Text lessMargin color="grey">
            {header}
          </Text>
          <Divider fitted hidden />
        </>
      )}

      {filteredTranslationInfo.map((info, index) => {
        const isLast = index === filteredTranslationInfo.length - 1;
        return (
          <Ref
            innerRef={(el): void => {
              isLastAdded && isLast && observerLastElementInList(el);
            }}
            key={uniqueId("prev_translation_bundled_")}
          >
            <Popup
              disabled={!info.contains_proofreading}
              content="Has associated proofreading requests"
              position="top right"
              wide
              size="small"
              mouseEnterDelay={popupDelay}
              trigger={
                <Segment
                  onClick={(): void => loadPreviousTranslation(info)}
                  style={{ cursor: "pointer" }}
                  data-testid={`previously-machine-translated-text-unknown-list-item`}
                >
                  <Text compact size="small">
                    {info.contains_proofreading && (
                      <>
                        <Icon name="how_to_reg" className="tw-text-sm" />{" "}
                      </>
                    )}
                    {fixLongName(
                      info.source_text || info.file_name || "",
                      info.contains_proofreading ? 80 : 100
                    )}
                  </Text>
                </Segment>
              }
            />
          </Ref>
        );
      })}

      {!!header && <Divider fitted hidden />}
    </>
  );
};
