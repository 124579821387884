import React, { useEffect, useMemo, useRef, useState } from "react";
import { LanguageCode } from "../customers/customerlanguages";
import {
  moveDateBackDays,
  formatDate,
} from "./previously-machine-translated-resources/PreviouslyMachineTranslatedRail";
import { getPreviousMachineTranslations } from "../api/machineTranslationApi";
import { useSelector } from "react-redux";
import { RootState } from "../utils/store";
import { TranslationInfo } from "./previously-machine-translated-resources/PreviouslyMachineTranslatedRailList";
import { Spinner } from "../utils/Spinner";
import { Icon, Toggle } from "../components/tailwind";

type Props = {
  sourceText: string;
  selectedSourceLanguage: LanguageCode;
  autoSearchPrevTranslations: boolean;
  loadPreviousTranslation: (info: TranslationInfo) => void;
};
export const MachineTranslateTextEmptyResults: React.FC<Props> = ({
  sourceText,
  selectedSourceLanguage,
  autoSearchPrevTranslations,
  loadPreviousTranslation,
}) => {
  const token = useSelector((state: RootState) => state.auth.token);
  const [search, setSearch] = useState<string>("");
  const [
    previousMachineTranslations,
    setPreviousMachineTranslations,
  ] = useState<TranslationInfo[]>([]);

  const previousMachineTranslationsWithOnlyProofreadingRequests = useMemo(
    () =>
      previousMachineTranslations.filter((info) => info.contains_proofreading),
    [previousMachineTranslations]
  );

  const [
    showOnlyWithProofreadingRequests,
    setShowOnlyWithProofreadingRequests,
  ] = useState(false);

  const displayList = useMemo(() => {
    const useList = showOnlyWithProofreadingRequests
      ? previousMachineTranslationsWithOnlyProofreadingRequests
      : previousMachineTranslations;
    if (!search) return useList;
    return useList.filter(
      ({ source_text }) =>
        source_text.toLowerCase().search(search.toLowerCase()) != -1
    );
  }, [
    search,
    previousMachineTranslations,
    showOnlyWithProofreadingRequests,
    previousMachineTranslationsWithOnlyProofreadingRequests,
  ]);

  const [
    isFetchingPreviousMachineTranslations,
    setIsFetchingPreviousMachineTranslations,
  ] = useState(false);

  const noReturnCount = useRef(0);
  const activeTimeout = useRef<any>();
  const mounted = useRef(true);

  const iterateGetPreviousMachineTranslations = (
    start: Date,
    end: Date
  ): void => {
    if (noReturnCount.current > 8 || !mounted.current) {
      setIsFetchingPreviousMachineTranslations(false);
      return;
    }
    activeTimeout.current = setTimeout(() => {
      getPreviousMachineTranslations(token, {
        start: formatDate(start),
        end: formatDate(end),
        query_string: sourceText,
        language_code: selectedSourceLanguage,
      }).then((prevTranslations) => {
        if (!prevTranslations.length) {
          noReturnCount.current++;
        } else if (noReturnCount.current > 0) {
          noReturnCount.current = 0;
        }
        if (mounted.current)
          setPreviousMachineTranslations((prev) => [
            ...prev,
            ...prevTranslations,
          ]);

        iterateGetPreviousMachineTranslations(
          moveDateBackDays(start, 31),
          moveDateBackDays(start, 1)
        );
      });
    }, 1000);
  };

  useEffect(() => {
    if (!autoSearchPrevTranslations) {
      setPreviousMachineTranslations([]);
      setIsFetchingPreviousMachineTranslations(false);

      return;
    }
    if (!sourceText || !token) {
      setIsFetchingPreviousMachineTranslations(false);
      return;
    }
    setIsFetchingPreviousMachineTranslations(true);
    activeTimeout.current = setTimeout(() => {
      setPreviousMachineTranslations([]);
      iterateGetPreviousMachineTranslations(
        moveDateBackDays(new Date(), 30),
        new Date()
      );
    }, 1000);

    return () => {
      if (activeTimeout.current) {
        clearTimeout(activeTimeout.current);
      }
      noReturnCount.current = 0;
    };
  }, [sourceText, selectedSourceLanguage, autoSearchPrevTranslations, token]);

  useEffect(() => {
    mounted.current = true;
    return () => {
      if (activeTimeout.current) {
        clearTimeout(activeTimeout.current);
      }
      noReturnCount.current = 0;
      mounted.current = false;
    };
  }, []);
  if (previousMachineTranslations.length) {
    return (
      <>
        <div className="tw-p-2.5 tw-border-b-2 -tw-mt-6">
          <div className="tw-grid tw-grid-cols-[1fr,_auto] tw-gap-2">
            <input
              type="text"
              className="txu-input"
              placeholder="Search Previous Translation..."
              value={search}
              onChange={({ target }) => setSearch(target.value)}
            />
            <Toggle
              label="Filter on Proofreading Requests"
              checked={showOnlyWithProofreadingRequests}
              setChecked={setShowOnlyWithProofreadingRequests}
            />
          </div>
          {!displayList.length &&
            showOnlyWithProofreadingRequests &&
            !isFetchingPreviousMachineTranslations &&
            !search && (
              <p className="tw-text-gray-500 tw-mt-4">
                The Search did not contain any Previous Translation with
                Proofreading Requests.
              </p>
            )}
          {!displayList.length && search && (
            <p className="tw-text-gray-500 tw-mt-4 tw-text-center">
              No results found
            </p>
          )}
        </div>
        <div className="tw-flex tw-flex-col tw-gap-2 tw-max-h-[60vh] tw-overflow-auto tw-rounded-md tw-p-2.5">
          {displayList.map((translationInfo, index) => (
            <div
              className="tw-border tw-p-4 hover:tw-ring-2 tw-ring-gray-500 tw-ring-offset-2 tw-transition-all tw-cursor-pointer tw-shadow-sm tw-rounded-md tw-flex tw-gap-2 tw-items-center"
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              onClick={(): void => loadPreviousTranslation(translationInfo)}
            >
              {translationInfo.contains_proofreading && (
                <Icon
                  name="how_to_reg"
                  className="tw-leading-[1]"
                  data-balloon="Contains Proofreading Request"
                  data-balloon-pos="right"
                />
              )}
              <p>{translationInfo.source_text}</p>
            </div>
          ))}
          {isFetchingPreviousMachineTranslations && (
            <div className="tw-sticky -tw-bottom-[8px] tw-bg-white/90 tw-pl-2">
              <Spinner />
            </div>
          )}
        </div>
      </>
    );
  }
  if (isFetchingPreviousMachineTranslations) {
    return (
      <>
        <div className="tw-grid tw-grid-cols-[1fr,_auto] tw-gap-2">
          <input
            type="text"
            className="txu-input"
            placeholder="Search Previous Translation..."
            value={search}
            onChange={({ target }) => setSearch(target.value)}
          />
          <Toggle
            label="Filter on Proofreading Requests"
            checked={showOnlyWithProofreadingRequests}
            setChecked={setShowOnlyWithProofreadingRequests}
          />
        </div>
        <div className="tw-flex tw-items-center tw-gap-2">
          <Spinner />
          <p className="tw-text-gray-500">
            Searching for Previous Translations...
          </p>
        </div>
      </>
    );
  }
  return (
    <div className="tw-rounded tw-border tw-bg-gray-50 tw-py-[5rem] tw-text-center">
      <p className="tw-text-gray-500 tw-m-0 tw-mb-2">
        Translate your text into multiple languages simultaneously using the
        selector above.
      </p>

      {noReturnCount.current > 0 && (
        <p className="tw-text-gray-500 tw-text-sm tw-m-0 tw-mt-4">
          Found no Previous Translations.
        </p>
      )}
    </div>
  );
};
