import React, { useCallback, useLayoutEffect, useRef, useState } from "react";
import { Dropdown, DropdownControls, Option } from "../dropdown/Dropdown";
import debounce from "lodash/debounce";

const DATA_INPUT_SELECTOR = "data-input-control";

type Props = {
  control?: DropdownControls;
  inputControlSelector: string;
  options: string;
  placeholder: string;
  clearable?: string;
  disabled?: string;
};

export const DjangoDropdownWidget: React.FC<Props> = ({
  control,
  inputControlSelector,
  options,
  placeholder,
  clearable = "true",
  disabled = "false",
}) => {
  const inputControl = useRef<HTMLInputElement>();
  const cancelDebounce = useRef(false);
  const [value, setValue] = useState<string | string[] | number | number[]>();
  const initialValue = useRef("");

  const parseValue = (value: string): string | string[] | number | number[] => {
    switch (control) {
      case "multi-search":
        return value ? (JSON.parse(value) as string[]) : [];
      default:
        return Number(value) || value || "";
    }
  };

  const stringifyValue = (
    value: string | string[] | number | number[]
  ): string => {
    if (!value) return "";
    switch (control) {
      case "multi-search":
        return JSON.stringify(value);
      default:
        return value as string;
    }
  };

  useLayoutEffect(() => {
    if (!inputControlSelector || inputControl.current) return;
    const input = document.querySelector<HTMLInputElement>(
      `input[${DATA_INPUT_SELECTOR}="${inputControlSelector}"]`
    );
    if (!input)
      throw new Error(
        `Can't find input control [${DATA_INPUT_SELECTOR}="${inputControlSelector}"]`
      );
    inputControl.current = input;
    initialValue.current = input.value;
    setValue(parseValue(input.value));
  }, [inputControlSelector]);

  const parseOptions = (): Option<string>[] => {
    const parsed = JSON.parse(options) as Option<string>[];
    return parsed;
  };

  const onChangeDebounce = useCallback(
    debounce((value: string) => {
      if (cancelDebounce.current) {
        return;
      }
      if (value === initialValue.current) {
        return;
      }
      inputControl.current.value = value;
      const event = new Event("change", { bubbles: true });
      inputControl.current.dispatchEvent(event);
    }, 1000),
    []
  );

  const onChange = (value: string | string[] | number | number[]): void => {
    setValue(value);
    const newValue = stringifyValue(value);
    switch (control) {
      case "multi-search": {
        // In some cases the form auto submits.
        // This allows the user to select more than one option before the form is submitted
        onChangeDebounce(newValue);
        break;
      }
      default: {
        inputControl.current.value = newValue;
        const event = new Event("change", { bubbles: true });
        inputControl.current.dispatchEvent(event);
      }
    }
  };

  const clearableBool = clearable === "true";
  const disabledBool = disabled === "true";
  switch (control) {
    case "button":
      return (
        <Dropdown
          control="button"
          onChange={onChange}
          options={parseOptions()}
          buttonProps={{}}
          value={value}
          content={placeholder}
          disabled={disabledBool}
        />
      );
    case "multi-search":
      return (
        <Dropdown
          control="multi-search"
          options={parseOptions()}
          onChange={onChange}
          value={(value as string[] | number[]) || []}
          placeholder={placeholder}
          badgeProps={{ clearable: true }}
          clearable={clearableBool}
          disabled={disabledBool}
          isOpenCallback={(open): void => {
            if (open) {
              cancelDebounce.current = true;
            } else {
              cancelDebounce.current = false;
              onChange(value);
            }
          }}
        />
      );

    default:
      return (
        <Dropdown
          control="search"
          options={parseOptions()}
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          clearable={clearableBool}
          disabled={disabledBool}
        />
      );
  }
};
