import React, { useEffect, useState } from "react";
import { Menu as SUIMenu, Tab as SUITab } from "semantic-ui-react";

import { useUrlState } from "../utils/react-custom-hooks/urlStateHandler";

export type MenuItem = {
  key: string; // used in query string as ?tab=key
  icon?: React.ReactNode;
  heading: string;
  subHeading?: string;
};

export type TabPane = {
  menuItem: MenuItem;
  content: React.ReactNode;
};

type TabProps = {
  panes: TabPane[];
  onChange?: (activeTab: string) => void;
};

type InternalTabProps = TabProps & {
  setActiveTab: (active: string) => void;
  activeTab: string;
};

const InternalTab: React.FC<InternalTabProps> = ({
  panes,
  setActiveTab,
  activeTab,
}) => {
  const tabKeys = panes.map((pane) => pane.menuItem.key);

  const makeMenuItem: (menuItem: MenuItem) => JSX.Element = (menuItem) => {
    let headerClass = "tw-m-0 tw-mb-1 tw-text-base";
    if (activeTab != menuItem.key) headerClass += " tw-text-gray-500";
    return (
      <SUIMenu.Item
        key={menuItem.key}
        className="tw-gap-3"
        data-testid={`tab-${menuItem.key}`}
      >
        {menuItem?.icon && <div className={headerClass}>{menuItem.icon}</div>}
        <div>
          <h4 className={headerClass}>{menuItem.heading}</h4>
          {menuItem?.subHeading && (
            <span className="tw-font-normal tw-text-gray-400">
              {menuItem.subHeading}
            </span>
          )}
        </div>
      </SUIMenu.Item>
    );
  };

  const suiPanes = panes.map((pane) => {
    return {
      menuItem: makeMenuItem(pane.menuItem),
      render: () => (
        <div
          className="tw-relative tw-p-4"
          data-testid={`tab-content-${pane.menuItem.key}`}
        >
          {pane.content}
        </div>
      ),
    };
  });

  return (
    <SUITab
      activeIndex={tabKeys.indexOf(activeTab)}
      onTabChange={(e, { activeIndex }): void => {
        setActiveTab(tabKeys[activeIndex as number]);
      }}
      menu={{ pointing: true, secondary: true }}
      panes={suiPanes}
    />
  );
};

const TabUsingUrlParam: React.FC<TabProps & { queryParamKey: string }> = ({
  panes,
  queryParamKey,
  onChange,
}) => {
  const tabKeys = panes.map((pane) => pane.menuItem.key);

  const {
    setParam,
    urlState: { [queryParamKey]: tab },
  } = useUrlState<{
    [key: string]: string;
  }>({
    [queryParamKey]: tabKeys[0],
  });
  useEffect(() => {
    onChange?.(tab);
  }, [tab]);
  return (
    <InternalTab
      panes={panes}
      activeTab={tab}
      setActiveTab={(active): void => setParam(queryParamKey, active)}
    />
  );
};

const TabUsingReactState: React.FC<TabProps> = ({ panes, onChange }) => {
  const tabKeys = panes.map((pane) => pane.menuItem.key);

  const [activeTab, setActiveTab] = useState<string>(tabKeys[0]);

  useEffect(() => {
    onChange?.(activeTab);
  }, [activeTab]);

  return (
    <InternalTab
      panes={panes}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      onChange={onChange}
    />
  );
};

type ExternalTabProps = TabProps & {
  stateHandler?: "url" | "react";
  queryParamKey?: string;
  onChange?: (activeTab: MenuItem["key"]) => void;
};

type ExternalTabUrlProps = ExternalTabProps & {
  stateHandler?: "url";
  queryParamKey?: string;
};

type ExternalTabReactProps = ExternalTabProps & {
  stateHandler: "react";
  queryParamKey?: never;
};
export const Tab: React.FC<ExternalTabUrlProps | ExternalTabReactProps> = ({
  panes,
  stateHandler,
  queryParamKey = "tab",
  onChange,
}) => {
  switch (stateHandler) {
    case "react":
      return <TabUsingReactState panes={panes} onChange={onChange} />;
    default:
      return (
        <TabUsingUrlParam
          panes={panes}
          queryParamKey={queryParamKey}
          onChange={onChange}
        />
      );
  }
};
