import React from "react";
import { NoTranslateTextarea } from "../NoTranslateTextarea";
import {
  ManualTranslationRequestForm,
  Props as ManualTranslationRequestFormProps,
} from "./ManualTranslationRequestForm";

type Props = Omit<ManualTranslationRequestFormProps, "children"> & {
  sourceText: string;
  setSourceText: (text: string) => void;
};

export const ManualTranslationRequestText: React.FC<Props> = ({
  sourceText,
  setSourceText,
  ...formProps
}) => {
  return (
    <>
      <NoTranslateTextarea
        value={sourceText}
        onChange={setSourceText}
        testId="manual-translation-request-textarea"
        placeholder="Enter text to translate..."
      ></NoTranslateTextarea>
      <ManualTranslationRequestForm {...formProps} />
    </>
  );
};
