import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, Icon } from "../../../components/tailwind";

const FALLBACK_DATA_ID = "machine-translation-fallback-order-data";
const FALLBACK_OPTIONS_ID = "machine-translation-fallback-order-options";

export const MachineTranslationFallbackOrder: React.FC = () => {
  const fallbackDataRef = useRef<HTMLInputElement>(null);
  const fallbackOptionsRef = useRef<HTMLInputElement>(null);

  const [fallbackData, _setFallbackData] = useState<string[]>([]);
  const [fallbackOptions, setFallbackOptions] = useState<string[][]>([]);
  const getValues = (): void => {
    fallbackDataRef.current = document.getElementById(
      FALLBACK_DATA_ID
    ) as HTMLInputElement;
    fallbackOptionsRef.current = document.getElementById(
      FALLBACK_OPTIONS_ID
    ) as HTMLInputElement;
    setFallbackData(JSON.parse(fallbackDataRef.current?.value));
    setFallbackOptions(JSON.parse(fallbackOptionsRef.current?.value));
  };

  const setFallbackData = (values: string[]): void => {
    _setFallbackData(values);
    fallbackDataRef.current.value = JSON.stringify(values);
  };

  useEffect(getValues, []);

  const displayPosition = useMemo(() => {
    const newOrder: string[][] = [];
    const addToTheBack: string[][] = [];
    for (const option of fallbackData) {
      const index = fallbackOptions.findIndex((item) => item[1] === option);
      if (index !== -1) {
        newOrder.push(fallbackOptions[index]);
      }
    }
    for (const option of fallbackOptions) {
      if (!fallbackData.includes(option[1])) {
        addToTheBack.push(option);
      }
    }
    return [...newOrder, ...addToTheBack];
  }, [fallbackOptions, fallbackData]);

  const moveUp = (value: string): void => {
    const currentIndex = fallbackData.findIndex((item) => item === value);

    const expectedIndex = currentIndex - 1;
    const newFallbackData = [
      ...fallbackData.slice(0, expectedIndex),
      fallbackData[currentIndex],
      ...fallbackData.slice(expectedIndex, currentIndex),
      ...fallbackData.slice(currentIndex + 1, fallbackData.length),
    ];
    setFallbackData(newFallbackData);
  };

  const moveDown = (value: string): void => {
    const currentIndex = fallbackData.findIndex((item) => item === value);
    const expectedIndex = currentIndex + 1;
    const newFallbackData = [
      ...fallbackData.slice(0, currentIndex),
      fallbackData[expectedIndex],
      ...fallbackData.slice(currentIndex, expectedIndex),
      ...fallbackData.slice(expectedIndex + 1, fallbackData.length),
    ];
    setFallbackData(newFallbackData);
  };

  const remove = (value: string): void => {
    const newFallbackData = fallbackData.filter((item) => item !== value);
    setFallbackData(newFallbackData);
  };

  const add = (value: string): void => {
    const newFallbackData = [...fallbackData, value];
    setFallbackData(newFallbackData);
  };

  const itemExcluded = (value: string): boolean => {
    return !fallbackData.includes(value);
  };

  return (
    <div className="manage-content tw-bg-white">
      <div className="tw-grid tw-place-items-center tw-mt-6 tw-gap-6">
        <h4>Machine Translation Fallback Order</h4>
        <div className="tw-flex tw-flex-col tw-gap-4">
          {displayPosition.map((item, index) => (
            <div
              key={item[1]}
              className="tw-ring-1 tw-ring-gray-200 tw-rounded-md tw-p-2 tw-min-w-[300px] tw-flex tw-items-center"
            >
              <div className="tw-flex tw-items-center tw-gap-2">
                <Button
                  data-balloon="Move up"
                  data-balloon-pos="left"
                  className="tw-mr-auto"
                  disabled={index === 0 || itemExcluded(item[1])}
                  variant="secondary-alt"
                  icon
                  compact
                  onClick={(e) => {
                    e.preventDefault();
                    moveUp(item[1]);
                  }}
                >
                  <Icon name="arrow_upward" />
                </Button>
                <Button
                  data-balloon="Move down"
                  data-balloon-pos="right"
                  className="tw-ml-auto"
                  disabled={
                    index === fallbackData.length - 1 || itemExcluded(item[1])
                  }
                  variant="secondary-alt"
                  icon
                  compact
                  onClick={(e) => {
                    e.preventDefault();
                    moveDown(item[1]);
                  }}
                >
                  <Icon name="arrow_downward" />
                </Button>
              </div>
              <p
                className={`tw-ml-8 ${
                  itemExcluded(item[1])
                    ? "tw-text-gray-500 tw-line-through"
                    : ""
                }`}
              >
                {item[0]}
              </p>
              {!itemExcluded(item[1]) ? (
                <Button
                  data-balloon="Exclude from fallback"
                  data-balloon-pos="right"
                  variant="secondary-alt"
                  icon
                  compact
                  className="tw-ml-auto"
                  onClick={(e) => {
                    e.preventDefault();
                    remove(item[1]);
                  }}
                >
                  <Icon name="delete_outline" />
                </Button>
              ) : (
                <Button
                  data-balloon="Include in fallback"
                  data-balloon-pos="right"
                  variant="primary-alt"
                  icon
                  compact
                  className="tw-ml-auto"
                  onClick={(e) => {
                    e.preventDefault();
                    add(item[1]);
                  }}
                >
                  <Icon name="add" />
                </Button>
              )}
            </div>
          ))}
        </div>
        <Button variant="primary" className="tw-min-w-[300px]" type="submit">
          Save
        </Button>
      </div>
    </div>
  );
};
