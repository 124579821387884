export const MODAL_HANDLER_SELECTOR = "[txu-modal-handler]";
export const MODAL_OPEN_ON_RENDER = "[txu-modal-auto-open]";

export const MODAL_ID = "txu-modal-id";

export const loadModalHandlers = (element: Element | Document): void => {
  const handlers = element.querySelectorAll(MODAL_HANDLER_SELECTOR);
  handlers.forEach((handler) => {
    const modalToHandle = element.querySelector(
      `#${handler.getAttribute(MODAL_ID)}`
    );
    if (modalToHandle) {
      handler.addEventListener("click", () => {
        (modalToHandle as HTMLDialogElement).showModal();
      });
    }
  });

  const autoOpenModals = element.querySelectorAll(MODAL_OPEN_ON_RENDER);
  autoOpenModals.forEach((modal) => {
    (modal as HTMLDialogElement).showModal();
  });
};
