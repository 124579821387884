import { UseGenericFetch, useGenericFetch } from "../api/customFetchHook";
import { getPreviousMachineTranslations } from "../api/machineTranslationApi";
import { LanguageCode } from "../customers/customerlanguages";
import { TranslationInfo } from "./previously-machine-translated-resources/PreviouslyMachineTranslatedRailList";

export function useGetPreviousMachineTranslations(
  start: string,
  end: string,
  type?: "text" | "file",
  query_string?: string,
  language_code?: LanguageCode
): UseGenericFetch<TranslationInfo[]> {
  return useGenericFetch<TranslationInfo[]>(
    [],
    getPreviousMachineTranslations,
    {
      start,
      end,
      query_string,
      language_code,
      type,
    }
  );
}
