import React, { useCallback, useEffect, useMemo, useState } from "react";
import debounce from "lodash/debounce";
import isNil from "lodash/isNil";
import {
  LanguageCode,
  renderLanguageFromCode,
} from "../customers/customerlanguages";
import { ProofreadingInfo } from "./previously-machine-translated-resources/PreviouslyMachineTranslatedRailList";
import { useGetCustomerQuery } from "../api/customerApi";
import {
  ProofreadRequestModal,
  Props as ProofreadRequestModalProps,
} from "./ProofreadRequestModal";
import { ProofreadStatusButton } from "./ProofreadStatusButton";
import { Button, Icon } from "../components/tailwind";
import { Text } from "../components/Text";
import { Placeholder } from "semantic-ui-react";
import { OverwrittenTextInfo, TaskStatus } from "./MachineTranslateText";
import { useSelector } from "react-redux";
import { modifyTranslatedText } from "../api/machineTranslationApi";
import { RootState } from "../utils/store";

type Props = {
  proofreadingInfo: ProofreadingInfo;
  overwrittenTextInfo: OverwrittenTextInfo;
  viewingProofreading: LanguageCode[];
  setViewingProofreading: React.Dispatch<React.SetStateAction<LanguageCode[]>>;
  languageCode: LanguageCode;
  translation: string;
  status: TaskStatus;
  sourceText: string;
  selectedSourceLanguage: LanguageCode;
  setRefreshTodaysTranslations: React.Dispatch<React.SetStateAction<boolean>>;
  onSendToProofreadingCallback: (
    jobIdsByLanguage?: { [key in LanguageCode]?: string }
  ) => void;
};

export const MachineTranslateTextResult: React.FC<Props> = ({
  proofreadingInfo,
  languageCode,
  translation,
  overwrittenTextInfo,
  viewingProofreading,
  setViewingProofreading,
  status,
  sourceText,
  selectedSourceLanguage,
  setRefreshTodaysTranslations,
  onSendToProofreadingCallback,
}) => {
  const { data: customer } = useGetCustomerQuery();

  const token = useSelector((state: RootState) => state.auth.token);

  const [overwrittenText, setOverwrittenText] = useState<string | null>();

  const activeText = useMemo(() => overwrittenText || translation, [
    translation,
    overwrittenText,
  ]);

  const displayLanguage = useMemo(() => {
    return (
      renderLanguageFromCode(languageCode, customer?.languages ?? []) ?? ""
    );
  }, [customer, languageCode]);

  useEffect(() => {
    setOverwrittenText(overwrittenTextInfo.text);
  }, [overwrittenTextInfo]);

  const debounceSaveOverwrittenText = useCallback(
    debounce((text: string) => {
      if (!overwrittenTextInfo?.translationEventId) {
        return;
      }
      modifyTranslatedText(
        token,
        overwrittenTextInfo?.translationEventId,
        text
      ).finally(() => {
        setRefreshTodaysTranslations(true);
      });
    }, 1000),
    [token, overwrittenTextInfo?.translationEventId]
  );

  const proofreadingModalButtonProps = useMemo((): ProofreadRequestModalProps["buttonProps"] => {
    return {
      disabled: !!proofreadingInfo?.job_id,
      variant: proofreadingInfo?.job_id ? "secondary-alt" : "primary",
      content: proofreadingInfo?.job_id
        ? "Sent for Proofreading"
        : "Send for Proofreading",
      "data-testid": `proofread-request-button-${languageCode}`,
      className: proofreadingInfo?.job_id ? "tw-hidden" : "",
    };
  }, [proofreadingInfo, languageCode]);
  return (
    <div key={languageCode}>
      <div className="tw-mb-2">
        {displayLanguage}{" "}
        {proofreadingInfo && (
          <p className="tw-float-end tw-text-sm">
            {proofreadingInfo.done ? (
              <span className="tw-flex tw-items-center tw-gap-1 tw-text-green-600">
                <Icon
                  name="check_circle"
                  className="tw-text-lg tw-leading-[1]"
                />
                Done
              </span>
            ) : (
              <span className="tw-flex tw-items-center tw-gap-1">
                <Icon name="schedule" className="tw-text-lg tw-leading-[1]" />
                Pending
              </span>
            )}
          </p>
        )}
      </div>
      {status.error ? (
        <>
          <Icon
            name="warning"
            className="tw-float-start tw-me-2 tw-text-red-600"
          />
          <Text compact color="grey">
            {status.error}
          </Text>
        </>
      ) : status.result ? (
        <>
          <div className="tw-min-h-16 tw-mb-2 tw-whitespace-pre-line tw-rounded tw-border tw-p-4">
            <div className="tw-mb-2 tw-flex tw-w-full tw-items-center">
              <span data-balloon="Copy to clipboard" data-balloon-pos="right">
                <Button
                  compact
                  data-testid="machine-translate-text-copy-button"
                  icon
                  onClick={(): void => {
                    navigator.clipboard.writeText(activeText);
                  }}
                  className="tw-me-2"
                  size="sm"
                >
                  <Icon name="content_copy" />
                </Button>
              </span>
              <div className="tw-ml-auto">
                {proofreadingInfo && (
                  <ProofreadStatusButton
                    language={languageCode}
                    proofreadingInfo={proofreadingInfo}
                    viewingProofreading={viewingProofreading}
                    setViewingProofreading={setViewingProofreading}
                  />
                )}
                <ProofreadRequestModal
                  proofreadingRequest={{
                    original: {
                      text: sourceText,
                      language: selectedSourceLanguage,
                    },
                    requests: [{ text: activeText, language: languageCode }],
                  }}
                  buttonProps={proofreadingModalButtonProps}
                  onSendToProofreadingCallback={onSendToProofreadingCallback}
                />
              </div>
            </div>
            <textarea
              data-testid={`machine-translate-text-result-textarea-${languageCode}`}
              className="txu-textarea"
              placeholder={
                translation
                  ? `${translation} (Original Translation)`
                  : "Translation"
              }
              value={isNil(overwrittenText) ? translation : overwrittenText}
              onChange={({ target }): void => {
                setOverwrittenText(target.value);
                debounceSaveOverwrittenText(target.value);
              }}
            />
          </div>
        </>
      ) : (
        <div className="tw-mb-2 tw-rounded tw-border tw-p-4">
          <Placeholder>
            <Placeholder.Paragraph>
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Paragraph>
          </Placeholder>
        </div>
      )}
    </div>
  );
};
