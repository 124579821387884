import React from "react";

import { Tab } from "../components/Tab";
import { ManualTranslationRequest } from "./manual-translation-request/ManualTranslationRequest";
import { ProofreadingRequest } from "./ProofreadingRequest";
import { ProofreadingDocumentsRequest } from "./ProofreadingDocumentsRequest";

export const TranslationRequest: React.FC = () => {
  const panes = [
    {
      menuItem: {
        key: "manual-translation",
        icon: <i className="material-icons">translate</i>,
        heading: "Manual Translation",
        subHeading: "File upload or Text",
      },
      content: <ManualTranslationRequest />,
    },
    {
      menuItem: {
        key: "proofreading",
        icon: <i className="material-icons">grading</i>,
        heading: "Proofreading",
        subHeading: "File upload",
      },
      content: <ProofreadingRequest />,
    },
    {
      menuItem: {
        key: "proofreading-documents",
        icon: <i className="material-icons">feed</i>,
        heading: "Proofreading",
        subHeading: "Documents in Textual",
      },
      content: <ProofreadingDocumentsRequest />,
    },
  ];

  return <Tab panes={panes} />;
};
