import mapValues from "lodash/mapValues";
import React from "react";

import { LanguageCode } from "../customers/customerlanguages";
import { OverwrittenTextInfo, TaskStatus } from "./MachineTranslateText";
import { MachineTranslateTextResult } from "./MachineTranslateTextResult";
import { ProofreadingInfo } from "./previously-machine-translated-resources/PreviouslyMachineTranslatedRailList";
import { Button } from "../components/tailwind";
import {
  DoneTranslateTextResponse,
  MachineTranslateTaskQueue,
} from "../api/machineTranslationApi";

type Props = {
  translateStatus: {
    [key in LanguageCode]?: TaskStatus;
  };
  setTranslateStatus: React.Dispatch<
    React.SetStateAction<{ [key in LanguageCode]?: TaskStatus }>
  >;
  viewingProofreading: LanguageCode[];
  setViewingProofreading: React.Dispatch<React.SetStateAction<LanguageCode[]>>;
  proofreadingRequests: { [key in LanguageCode]?: ProofreadingInfo };
  overwrittenTexts: { [key in LanguageCode]?: OverwrittenTextInfo };
  sourceText: string;
  selectedSourceLanguage: LanguageCode;
  setRefreshTodaysTranslations: React.Dispatch<React.SetStateAction<boolean>>;
  allTranslateJobsDone: boolean;
  machineTranslateTaskQueue: MachineTranslateTaskQueue<
    DoneTranslateTextResponse
  >;
  onSendToProofreadingCallback: (
    jobIdsByLanguage?: { [key in LanguageCode]?: string }
  ) => void;
};

export const MachineTranslateTextResults: React.FC<Props> = ({
  translateStatus,
  setTranslateStatus,
  viewingProofreading,
  setViewingProofreading,
  proofreadingRequests,
  overwrittenTexts,
  sourceText,
  selectedSourceLanguage,
  setRefreshTodaysTranslations,
  allTranslateJobsDone,
  machineTranslateTaskQueue,
  onSendToProofreadingCallback,
}) => {
  return (
    <>
      <div
        data-testid="machine-translate-text-tasks"
        className="tw-flex tw-w-full tw-flex-col tw-gap-2"
      >
        {(Object.entries(translateStatus) as [LanguageCode, TaskStatus][]).map(
          ([language, status]) => {
            const proofreadingInfo = proofreadingRequests[language];
            const viewingText =
              viewingProofreading.includes(language) && proofreadingInfo
                ? proofreadingInfo?.final_text
                : status.result?.translated_text;

            return (
              <MachineTranslateTextResult
                key={language}
                languageCode={language}
                overwrittenTextInfo={
                  overwrittenTexts[language] || {
                    text: null,
                    translationEventId: status.result?.translation_event_id,
                  }
                }
                translation={viewingText}
                viewingProofreading={viewingProofreading}
                setViewingProofreading={setViewingProofreading}
                proofreadingInfo={proofreadingInfo}
                sourceText={sourceText}
                selectedSourceLanguage={selectedSourceLanguage}
                status={status}
                setRefreshTodaysTranslations={setRefreshTodaysTranslations}
                onSendToProofreadingCallback={onSendToProofreadingCallback}
              />
            );
          }
        )}
      </div>

      <div style={{ textAlign: "right" }}>
        {!allTranslateJobsDone && (
          <Button
            variant="primary-alt"
            content="Cancel"
            data-testid="machine-translate-text-done-button"
            onClick={(): void => {
              machineTranslateTaskQueue.cancelAll();
              setTranslateStatus(
                mapValues(
                  translateStatus,
                  (status): TaskStatus => {
                    if (!status.loading) return status;
                    else
                      return {
                        loading: false,
                        error: "Cancelled",
                        result: null,
                      };
                  }
                )
              );
            }}
            className="tw-mt-2"
          />
        )}
      </div>
    </>
  );
};
