import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { Divider, Icon, Input, Loader, Rail } from "semantic-ui-react";
import { Text } from "../../components/Text";
import {
  PreviouslyMachineTranslatedRailList,
  TranslationInfo,
} from "./PreviouslyMachineTranslatedRailList";
import { PreviouslyMachineTranslatedRailListBundledDates } from "./PreviouslyMachineTranslatedRailListBundledDates";

const StyledRail = styled(Rail)<{ $show?: boolean }>`
  &&&&& {
    top: -60px;
    > .scrolling-content {
      max-height: calc(100vh - 300px);
      overflow-y: auto;
    }
    &.compact {
      height: unset;
      z-index: 2;
      padding: 3px 5px;
      border-width: 1px;
      border-color: ${(props): string =>
        props.$show ? "rgba(0, 0, 0, 0.1)" : "transparent"};
      border-radius: 4px;
      background: ${(props): string => (props.$show ? "white" : "transparent")};
      > .prev-translation-header {
        cursor: pointer;
      }
      > .scrolling-content {
        display: ${(props): string => (props.$show ? "block" : "none")};
        padding-top: 2px;
      }
      > .search-input {
        display: ${(props): string => (props.$show ? "flex" : "none")};
      }
    }
  }
`;
// DATE UTILS
export const formatDate = (date: Date): string => {
  return date.toISOString().substring(0, 10);
};

export const moveDateBackDays = (date: Date, days: number): Date => {
  const newDate = new Date(date);
  newDate.setDate(date.getDate() - days);
  return newDate;
};

const isToday = (date: Date): boolean => {
  const today = new Date();
  return date.toDateString() === today.toDateString();
};

const isYesterday = (date: Date): boolean => {
  const today = new Date();
  const yesterday = moveDateBackDays(today, 1);
  return date.toDateString() === yesterday.toDateString();
};

export const getDateRangeEnd = (prevDate: Date): Date => {
  return moveDateBackDays(prevDate, 1);
};

const getHeader = (date: Date, dates: Date[]): string | undefined => {
  if (isToday(date)) {
    return "Today";
  }
  if (isYesterday(date)) {
    return "Yesterday";
  }
  if (dates.some((date) => isToday(date) || isYesterday(date))) {
    return "Older";
  }
  return undefined;
};

// days we should pad backwards when adding more dates
const DEFAULT_DAYS_BACK = 30;

// If 50 > request do not return any result assume there is no more results to get and halt the infinite scroll
const NO_RETURNED_LIMIT = 50;

// If there is 2 > request inflight wait until inflight request < 2 before sending a new request.
const ALLOWED_INFLIGHT_REQUESTS = 2;

type Props = {
  loadPreviousTranslation: (info: TranslationInfo) => void;
  refreshTodaysTranslations: boolean;
  setRefreshTodaysTranslations: (refresh: boolean) => void;
  type?: "text" | "file";
};

export const PreviouslyMachineTranslatedRail: React.FC<Props> = ({
  loadPreviousTranslation,
  refreshTodaysTranslations,
  setRefreshTodaysTranslations,
  type = "text",
}) => {
  const mounted = useRef(true);

  // SEARCH
  const [searchPrevTranslations, setSearchPrevTranslations] = useState<string>(
    ""
  );

  // Dates that are showing and returned something
  const [datesShowing, setDatesShowing] = useState<Date[]>([
    new Date(), // Today
    moveDateBackDays(new Date(), 1), // Yesterday
  ]);
  // All the dates that has been tried. This list is used to calculate the next requests start and end dates.
  const [datesTried, setDatesTried] = useState<Date[]>([]);

  const shouldBundleDate = (date: Date): boolean => {
    return !(isToday(date) || isYesterday(date));
  };

  const bundledDates = useMemo(() => {
    return datesShowing.filter((date) => shouldBundleDate(date));
  }, [datesShowing]);

  const [foundNoTranslations, setFoundNoTranslations] = useState(false);

  const handleUpdateDates = (date: Date, success: boolean): void => {
    if (!success) {
      setDatesShowing((prev) => prev.filter((d) => d !== date));
    }
    if (!datesTried.includes(date)) {
      setDatesTried((prev) => [...prev, date]);
    }
  };

  // Preloaded next date for datesShowing state
  const nextDate = useRef<Date>();

  // handlers for limiters to avoid overload of requests
  const noReturnCount = useRef(0);
  const inFlightRequests = useRef(0);
  const setNoReturnCount = (): void => {
    noReturnCount.current += 1;
  };
  const incrementInFlightRequest = (): void => {
    inFlightRequests.current += 1;
  };
  const decrementInFlightRequest = (): void => {
    if (inFlightRequests.current === 0) {
      return;
    }
    inFlightRequests.current -= 1;
  };

  // Loading handlers
  const [isLoading, setIsLoading] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | number>();
  const setLoading = (): void => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current as NodeJS.Timeout);
    }
    setIsLoading(true);
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 1500);
    timeoutRef.current = timeout;
  };

  // infinite scroll handlers
  const observer = useRef<IntersectionObserver>();
  const observerLastElementInList = (element: Element): void => {
    if (!mounted.current) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((elements) => {
      if (
        inFlightRequests.current >= ALLOWED_INFLIGHT_REQUESTS ||
        noReturnCount.current >= NO_RETURNED_LIMIT
      )
        return;
      if (elements[0].isIntersecting) {
        setDatesShowing((prevState) => [...prevState, nextDate.current]);
      }
    });
    if (element) observer.current.observe(element);
  };

  useEffect(() => {
    // Preload next date if datesShowing state changed
    const prevDate = datesTried.length
      ? datesTried[datesTried.length - 1]
      : new Date();

    if (isToday(prevDate)) {
      nextDate.current = moveDateBackDays(prevDate, 1);
    } else {
      nextDate.current = moveDateBackDays(prevDate, DEFAULT_DAYS_BACK);
    }
  }, [datesTried]);

  useEffect(() => {
    if (!datesShowing.length && !isLoading) {
      if (noReturnCount.current >= NO_RETURNED_LIMIT) {
        setFoundNoTranslations(true);
        return;
      }
      setDatesShowing((prevState) => [...prevState, nextDate.current]);
    }
  }, [datesShowing, datesTried, isLoading]);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current as NodeJS.Timeout);
      }
    };
  }, []);

  useEffect(() => {
    // Load back in Today if it is not showing when user makes new translation
    if (
      refreshTodaysTranslations &&
      datesShowing.every(
        (date) => date.toDateString() != new Date().toDateString()
      )
    ) {
      setDatesShowing((prev) => [new Date(), ...prev]);
      setRefreshTodaysTranslations(false);
    }
  }, [refreshTodaysTranslations]);

  const [show, setShow] = useState(false);

  const loadPreviousTranslationIntersect = (info: TranslationInfo): void => {
    setShow(false);
    loadPreviousTranslation(info);
  };

  return (
    <StyledRail
      position="right"
      close="very"
      internal={true}
      className="compact"
      $show={show}
    >
      <span
        onClick={(): void => setShow(!show)}
        className="prev-translation-header"
      >
        <Text as="h5" textAlignment="right">
          <>
            <Loader
              as="span"
              active
              size="tiny"
              inline
              style={{
                visibility: isLoading ? "visible" : "hidden",
                marginRight: "10px",
              }}
              data-testid="previously-machine-translated-text-rail-loader"
            />
            Previous translations
            <Icon name="dropdown" style={{ marginLeft: "5px" }} />
          </>
        </Text>
      </span>
      {!foundNoTranslations && type === "text" && (
        <>
          <Input
            data-testid="previously-machine-translated-text-rail-search-input"
            disabled={!datesShowing.length}
            className="search-input"
            icon="search"
            placeholder="Search translation"
            fluid
            value={searchPrevTranslations}
            onChange={(_, { value }): void => setSearchPrevTranslations(value)}
          />
          <Divider hidden fitted />
        </>
      )}
      <div className="scrolling-content">
        {foundNoTranslations && (
          <Text lessMargin color="grey">
            No translations made yet
          </Text>
        )}
        {datesShowing.map((start, index) => {
          const isLastAdded = index === datesShowing.length - 1;
          // Skip both Today and yesterday when looking for a start and end dates
          const prevDate = datesTried.length
            ? datesTried[datesTried.length - 1]
            : undefined;

          let end = start;
          if (!(isToday(start) || isYesterday(start))) {
            end = getDateRangeEnd(prevDate);
          }
          if (shouldBundleDate(start)) return;
          return (
            <PreviouslyMachineTranslatedRailList
              header={getHeader(start, datesShowing)}
              key={start.toDateString()}
              start={start}
              end={end}
              setNoReturnCount={setNoReturnCount}
              incrementInFlightRequest={incrementInFlightRequest}
              decrementInFlightRequest={decrementInFlightRequest}
              observerLastElementInList={observerLastElementInList}
              isLastAdded={isLastAdded}
              handleUpdateDates={handleUpdateDates}
              setIsLoading={setLoading}
              loadPreviousTranslation={loadPreviousTranslationIntersect}
              refreshTodaysTranslations={
                isToday(start) && refreshTodaysTranslations
              }
              setRefreshTodaysTranslations={
                isToday(start) && setRefreshTodaysTranslations
              }
              searchPrevTranslations={searchPrevTranslations}
              type={type}
            />
          );
        })}
        {!!bundledDates.length && !!datesTried.length && (
          <PreviouslyMachineTranslatedRailListBundledDates
            type={type}
            header={getHeader(bundledDates[0], datesShowing)}
            lastDate={datesTried[datesTried.length - 1]}
            dates={bundledDates}
            setNoReturnCount={setNoReturnCount}
            incrementInFlightRequest={incrementInFlightRequest}
            decrementInFlightRequest={decrementInFlightRequest}
            observerLastElementInList={observerLastElementInList}
            handleUpdateDates={handleUpdateDates}
            setIsLoading={setLoading}
            loadPreviousTranslation={loadPreviousTranslationIntersect}
            searchPrevTranslations={searchPrevTranslations}
          />
        )}
      </div>
    </StyledRail>
  );
};
