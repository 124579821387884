/* eslint-disable @typescript-eslint/no-var-requires */
import React from "react";
import { Provider } from "react-redux";
import { store } from "./store";
import { setCredentials } from "../api/authSlice";
import ReactDOM from "react-dom";
import { uuidv4 } from "./uuidUtils";
import { PageDimmer } from "../components/tailwind";
import { PageDimmerProps } from "../components/tailwind/dimmer/PageDimmer";

export const getComponentByName = (name: string): React.ComponentType => {
  switch (name) {
    case "Alerts":
      return require("../alerts/Alerts").Alerts;
    case "ChannelEditButton":
      return require("../products/channel-tab/ChannelEditButton")
        .ChannelEditButton;
    case "ChannelEditTab":
      return require("../products/channel-tab/ChannelEditTab").ChannelEditTab;
    case "ChannelLanguageSettings":
      return require("../customers/ChannelLanguageSettings")
        .ChannelLanguageSettings;
    case "CopyToFromButton":
      return require("../products/actions/CopyToFromButton").CopyToFromButton;
    case "DocumentStructures":
      return require("../planner/document-structure/manage/DocumentStructures")
        .DocumentStructures;
    case "DjangoToast":
      return require("../components/DjangoToast").DjangoToast;
    case "EditTemplateInline":
      return require("../products/product-list/EditTemplateInlineSingleVariable")
        .EditTemplateInline;
    case "Fieldsets":
      return require("../products/manage/fieldsets/Fieldsets").Fieldsets;
    case "FreeTextEditor":
      return require("../products/fluent-tab/FreeTextEditor")
        .FreeTextEditorComp;
    case "ManagePromptTabs":
      return require("../customers/gpt/manage/ManagePromptTabs")
        .ManagePromptTabs;
    case "ManageOpenAIAssistants":
      return require("../customers/gpt/manage/ManageOpenAIAssistants")
        .ManageOpenAIAssistants;
    case "MappingsDownloadButton":
      return require("../utils/MappingsDownloadButton").MappingsDownloadButton;
    case "PrefillFromCategoryModal":
      return require("../products/sections-tab/templates/PrefillFromCategoryModal")
        .PrefillFromCategoryModal;
    case "PrefillFromCategoryModalToolbar":
      return require("../products/sections-tab/templates/PrefillFromCategoryModalToolbar")
        .PrefillFromCategoryModalToolbar;
    case "ProductDetailCopyAssistant":
      return require("../products/copy-assistant/ProductDetailCopyAssistant")
        .ProductDetailCopyAssistant;
    case "ProductDetailEditParentButton":
      return require("../products/ProductDetailEditParentButton")
        .ProductDetailEditParentButton;
    case "ProductDetailEditTab":
      return require("../products/edit-tab/ProductDetailEditTab")
        .ProductDetailEditTab;
    case "ProductDetailEnrichTab":
      return require("../products/enrich-tab/ProductDetailEnrichTab")
        .ProductDetailEnrichTab;
    case "ProductDetailImages":
      return require("../products/ProductDetailImages").ProductDetailImages;
    case "ProductDetailLockScreen":
      return require("../products/ProductDetailLockScreen")
        .ProductDetailLockScreen;
    case "ProductDetailSEOTab":
      return require("../products/seo-tab/ProductDetailSEOTab")
        .ProductDetailSEOTab;
    case "ProductList":
      return require("../products/product-list/ProductList").ProductList;
    case "ProductStatusButton":
      return require("../products/status/ProductStatusButton")
        .ProductStatusButton;
    case "PromptSelectionRules":
      return require("../customers/gpt/PromptSelectionRules")
        .PromptSelectionRules;
    case "PromptSuggestions":
      return require("../customers/gpt/PromptSuggestions").PromptSuggestions;
    case "PublishButton":
      return require("../products/publish/PublishButton").PublishButton;
    case "PublishDetailDataModal":
      return require("../products/actions/PublishDetailDataModal")
        .PublishDetailDataModal;
    case "RefreshFromImportedData":
      return require("../products/actions/RefreshFromImportedData")
        .RefreshFromImportedData;
    case "RunPipeline":
      return require("../products/actions/RunPipeline").RunPipeline;
    case "SaveAsCategoryModal":
      return require("../products/sections-tab/templates/SaveAsCategoryModal")
        .SaveAsCategoryModal;
    case "GenerateAITexts":
      return require("../products/bulk-actions/generate-ai-texts/GenerateAITexts")
        .GenerateAITexts;
    case "SaveForAllLanguagesButton":
      return require("../vocabulary/SaveForAllLanguagesButton")
        .SaveForAllLanguagesButton;
    case "SelectTranslationsButton":
      return require("../products/SelectTranslationsButton")
        .SelectTranslationsButton;
    case "TemplatesTabLabelSelector":
      return require("../vocabulary/templatesTabLabelSelector/TemplatesTabLabelSelector")
        .TemplatesTabLabelSelector;
    case "MachineTranslate":
      return require("../machine-translation/MachineTranslate")
        .MachineTranslate;
    case "TranslationRequest":
      return require("../machine-translation/TranslationRequest")
        .TranslationRequest;
    case "ManualTranslationRequest":
      return require("../machine-translation/manual-translation-request/ManualTranslationRequest")
        .ManualTranslationRequest;
    case "ProofreadingRequest":
      return require("../machine-translation/ProofreadingRequest")
        .ProofreadingRequest;
    case "VocabularyProductCountButton":
      return require("../vocabulary/VocabularyProductCountButton")
        .VocabularyProductCountButton;
    case "ManageTemplateLabels":
      return require("../vocabulary/manage/template-labels/ManageTemplateLabels")
        .ManageTemplateLabels;
    case "EmptyFormInputs":
      return require("../machine-translation/manage/glossaries/EmptyFormInputs")
        .EmptyFormInputs;
    case "Pipelines":
      return require("../products/manage/pipelines/Pipelines").Pipelines;
    case "GenerateRuleBasedTextsChannelLanguagePairSelector":
      return require("../products/manage/pipelines/bulk-action-components/GenerateRuleBasedTextsChannelLanguagePairSelector")
        .GenerateRuleBasedTextsChannelLanguagePairSelector;
    case "AddDataActionForm":
      return require("../products/bulk-actions/add-data-action/AddDataActionForm")
        .AddDataActionForm;
    case "MachineTranslationConfigGroup":
      return require("../machine-translation/manage/MachineTranslationConfigGroup/MachineTranslationConfigGroup")
        .MachineTranslationConfigGroup;
    case "DjangoDropdownWidget":
      return require("../components/tailwind/django-widgets/DjangoDropdownWidget")
        .DjangoDropdownWidget;
    case "Jobs":
      return require("../customers/jobs/Jobs").Jobs;
    case "JobDetail":
      return require("../customers/jobs/JobDetail").JobDetail;
    case "MachineTranslationFallbackOrder":
      return require("../machine-translation/manage/MachineTranslationFallbackOrder/MachineTranslationFallbackOrder")
        .MachineTranslationFallbackOrder;
    default: {
      throw new Error(`Not implemented: ${name}`);
    }
  }
};

export const getSkeletonComponentByName = (
  name: string
): React.ComponentType => {
  switch (name) {
    case "ProductDetailsTextPreviewSkeleton":
      return require("../components/skeletons/ProductDetailsTextPreviewSkeleton")
        .ProductDetailsTextPreviewSkeleton;
    case "ProductDetailsTextPreviewItemSkeleton":
      return require("../components/skeletons/ProductDetailsTextPreviewSkeleton")
        .ProductDetailsTextPreviewItemSkeleton;
    case "ProductDetailDescriptionFormSkeleton":
      return require("../components/skeletons/ProductDetailDescriptionFormSkeleton")
        .ProductDetailDescriptionFormSkeleton;
    case "ProductDetailTabsSkeleton":
      return require("../components/skeletons/ProductDetailTabsSkeleton")
        .ProductDetailTabsSkeleton;
    default: {
      throw new Error(`Not implemented: ${name}`);
    }
  }
};

export const renderReduxElement = (dataset: DOMStringMap): JSX.Element => {
  const Component = getComponentByName(dataset.reactComponent);
  const props = Object.assign({}, dataset);
  const { token } = props;
  if (token) {
    store.dispatch(setCredentials({ token }));
    delete props.token;
  }
  return (
    <Provider store={store}>
      <Component {...props} />
    </Provider>
  );
};

export const renderReduxElementProvidedProps = (
  Component: React.ComponentType,
  props: { [key: string]: any }
): JSX.Element => {
  const { token } = props;
  if (token) {
    store.dispatch(setCredentials({ token }));
    delete props.token;
  }
  return (
    <Provider store={store}>
      <Component {...props} />
    </Provider>
  );
};

export const renderPageDimmer = (props: PageDimmerProps): void => {
  const el = document.querySelector("div[data-page-dimmer-wrapper]");
  ReactDOM.render(
    renderReduxElementProvidedProps(PageDimmer, {
      ...props,
    }),
    el
  );
};
export const openTemplateBuilderModal = (templateVocabId?: number): void => {
  const el = document.querySelector("div[data-template-builder-wrapper]");
  const TemplateBuilderModal = require("../vocabulary/template-builder/TemplateBuilderModal")
    .TemplateBuilderModal;
  ReactDOM.render(
    renderReduxElementProvidedProps(TemplateBuilderModal, {
      templateVocabId,
      key: uuidv4(),
    }),
    el
  );
};
