import React, { useEffect, useMemo } from "react";
import uniqueId from "lodash/uniqueId";
import { Divider, Popup, Ref, Segment } from "semantic-ui-react";
import { fixLongName } from "../../products/copy-assistant/utils";
import { Text } from "../../components/Text";
import { useGetPreviousMachineTranslations } from "../customHooks";
import { formatDate } from "./PreviouslyMachineTranslatedRail";
import { LanguageCode } from "../../customers/customerlanguages";
import { popupDelay } from "../../customers/gpt/types";
import { Icon } from "../../components/tailwind";

export type ProofreadingInfo = {
  done: boolean;
  final_text?: string | null;
  final_file_path?: string | null;
  file_name?: string | null;
  download_link?: string | null;
  job_id: string;
};
export type Translation = {
  translated_text?: string | null;
  proofreading_info: ProofreadingInfo | null;
  translation_event_id: number | null;
  overwritten_text?: string | null;
  file_name?: string | null;
  translated_file_path?: string | null;
  download_link?: string | null;
};

export type TranslationInfo = {
  source_text?: string | null;
  source_context: string;
  source_file_path?: string | null;
  file_name?: string | null;
  download_link?: string | null;
  source_language: LanguageCode;
  contains_proofreading: boolean;
  translations: {
    [key in LanguageCode]: Translation;
  };
};

type Props = {
  header?: string;
  start: Date;
  end: Date;
  setNoReturnCount: () => void;
  incrementInFlightRequest: () => void;
  decrementInFlightRequest: () => void;
  observerLastElementInList: (element: Element) => void;
  setIsLoading: () => void;
  isLastAdded: boolean;
  handleUpdateDates: (date: Date, success: boolean) => void;
  loadPreviousTranslation: (info: TranslationInfo) => void;
  refreshTodaysTranslations?: boolean;
  setRefreshTodaysTranslations?: (refresh: boolean) => void;
  searchPrevTranslations: string;
  type?: "text" | "file";
};

export const PreviouslyMachineTranslatedRailList: React.FC<Props> = ({
  header,
  start,
  end,
  setNoReturnCount,
  incrementInFlightRequest,
  decrementInFlightRequest,
  observerLastElementInList,
  setIsLoading,
  isLastAdded,
  handleUpdateDates,
  loadPreviousTranslation,
  refreshTodaysTranslations,
  setRefreshTodaysTranslations,
  searchPrevTranslations,
  type = "text",
}) => {
  const {
    data: translationInfo,
    isFetching,
    reFetch,
  } = useGetPreviousMachineTranslations(
    formatDate(start),
    formatDate(end),
    type
  );

  const filteredTranslationInfo = useMemo(() => {
    if (!searchPrevTranslations) return translationInfo;
    return translationInfo.filter(
      ({ source_text }) =>
        source_text
          .toLowerCase()
          .search(searchPrevTranslations.toLowerCase()) != -1
    );
  }, [searchPrevTranslations, translationInfo]);

  const noTranslationInfo = useMemo(() => {
    if (isFetching) return false;
    return !translationInfo.length;
  }, [isFetching]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (isFetching) {
        incrementInFlightRequest();
        setIsLoading();
      } else {
        decrementInFlightRequest();
      }
    }
    return () => {
      mounted = false;
    };
  }, [isFetching]);

  useEffect(() => {
    if (refreshTodaysTranslations) {
      reFetch();
      setRefreshTodaysTranslations?.(false);
    }
  }, [refreshTodaysTranslations]);

  useEffect(() => {
    let mounted = true;
    if (!mounted) return;
    handleUpdateDates(start, !noTranslationInfo);
    if (noTranslationInfo) {
      setNoReturnCount();
    }
    return () => {
      mounted = false;
    };
  }, [noTranslationInfo]);

  if (noTranslationInfo || isFetching || !filteredTranslationInfo.length)
    return <></>;

  return (
    <>
      {!!header && (
        <>
          <Text lessMargin color="grey">
            {header}
          </Text>
          <Divider fitted hidden />
        </>
      )}
      <Segment.Group
        size="small"
        style={{ marginBlock: header ? "4px" : "unset" }}
        data-testid={`previously-machine-translated-text-${header}-list`}
      >
        {filteredTranslationInfo.map((info, index) => {
          const isLast = index === filteredTranslationInfo.length - 1;
          return (
            <Ref
              innerRef={(el): void => {
                isLastAdded && isLast && observerLastElementInList(el);
              }}
              key={uniqueId("prev_translation_")}
            >
              <Popup
                disabled={!info.contains_proofreading}
                content="Has associated proofreading requests"
                position="top right"
                wide
                size="small"
                mouseEnterDelay={popupDelay}
                trigger={
                  <Segment
                    onClick={(): void => loadPreviousTranslation(info)}
                    style={{ cursor: "pointer" }}
                    data-testid={`previously-machine-translated-text-${
                      header || "unknown"
                    }-list-item`}
                  >
                    {info.source_text && (
                      <Text compact size="small">
                        {info.contains_proofreading && (
                          <>
                            <Icon name="how_to_reg" className="tw-text-sm" />{" "}
                          </>
                        )}
                        {fixLongName(info.source_text, 100)}
                      </Text>
                    )}
                    {info.file_name && (
                      <Text compact size="small">
                        {info.contains_proofreading && (
                          <>
                            <Icon name="how_to_reg" className="tw-text-sm" />{" "}
                          </>
                        )}
                        {fixLongName(info.file_name, 100)}
                      </Text>
                    )}
                  </Segment>
                }
              />
            </Ref>
          );
        })}
      </Segment.Group>
      {!!header && <Divider fitted hidden />}
    </>
  );
};
