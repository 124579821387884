export const BASE_SELECTOR = "[txu-handler]";
export const DISABLE_VALUE_SELECTOR = "[txu-handler='disable-checkbox']";
const CHECKBOX_TAG_SELECTOR = `input[type="checkbox"]${BASE_SELECTOR}`;

const handleCheckAllInGroup = (checked: boolean, group: string): void => {
  const itemsInGroup = document.querySelectorAll<HTMLInputElement>(
    `[txu-group="${group}"]`
  );
  itemsInGroup.forEach((item) => {
    if (!item.disabled) {
      item.checked = checked;
    }
  });
};

const handleToggleGroupControlState = (group: string): void => {
  const itemsInGroup = document.querySelectorAll<HTMLInputElement>(
    `[txu-group="${group}"]`
  );
  const control = document.querySelector<HTMLInputElement>(
    `[txu-toggle-group="${group}"]`
  );

  if (!control) return;
  if (!itemsInGroup.length) {
    // If there are no items in the group, therefore the control has no effect and should be disabled
    control.checked = false;
    control.indeterminate = false;
    control.disabled = true;
    return;
  }
  let amountChecked = 0;
  let totalDisabledItems = 0;
  itemsInGroup.forEach((item) => {
    if (item.checked) {
      amountChecked++;
    }
    if (item.disabled) totalDisabledItems++;
  });

  const relevantLength = itemsInGroup.length - totalDisabledItems;
  if (amountChecked >= relevantLength) {
    control.checked = true;
    control.indeterminate = false;
  } else if (amountChecked) {
    control.indeterminate = true;
    control.checked = false;
  } else {
    control.indeterminate = false;
    control.checked = false;
  }
};

/*
  Disable a checkbox option in a group of checkboxes based on selectedValue
*/
const toggleDisabledState = (
  selectedValue: string,
  groupName: string,
  element: Element | Document
): void => {
  const groups = element.querySelectorAll<HTMLInputElement>(
    `[txu-group="${groupName}"]`
  );
  groups.forEach((group) => {
    if (group.value === selectedValue) {
      group.checked = false;
      group.disabled = true;
      group.parentElement.classList.add("tw-text-gray-400");
    } else {
      group.disabled = false;
      group.parentElement.classList.remove("tw-text-gray-400");
    }
  });
};

export const loadCheckboxTagHandlers = (element: Element | Document): void => {
  const checkboxes = element.querySelectorAll(CHECKBOX_TAG_SELECTOR);

  /*
    If a element has txu-handler="disable-checkbox", the value of that element is used to disable a checkbox option in a group of checkboxes.
  */
  const disableControls = element.querySelectorAll(
    `${BASE_SELECTOR}${DISABLE_VALUE_SELECTOR}`
  );
  if (disableControls) {
    disableControls.forEach((control) => {
      const selectedValue = (control as HTMLSelectElement).value;
      const groupName = control.getAttribute("txu-toggle-group");
      toggleDisabledState(selectedValue, groupName, element);
      control.addEventListener("change", () => {
        const selectedValue = (control as HTMLSelectElement).value;
        const groupName = control.getAttribute("txu-toggle-group");
        toggleDisabledState(selectedValue, groupName, element);
      });
    });
  }

  checkboxes.forEach((checkbox) => {
    const group = checkbox.getAttribute("txu-toggle-group");
    if (group) {
      handleToggleGroupControlState(group);
      checkbox.addEventListener("change", (event) => {
        const checked = (event.target as HTMLInputElement).checked;
        handleCheckAllInGroup(checked, group);
      });
      const itemsInGroup = document.querySelectorAll<HTMLInputElement>(
        `[txu-group="${group}"]`
      );
      itemsInGroup.forEach((item) => {
        item.addEventListener("change", () => {
          handleToggleGroupControlState(group);
        });
      });
    }
  });
};
