import React from "react";
import { useGetCustomerQuery } from "../../api/customerApi";

export type Props = {
  email: string;
  setEmail: (email: string) => void;
  isUrgent: boolean;
  setIsUrgent: (isUrgent: boolean) => void;
  additionalInstructions: string;
  setAdditionalInstructions: (additionalInstructions: string) => void;
};
export const ManualTranslationRequestForm: React.FC<Props> = ({
  email,
  setEmail,
  isUrgent,
  setIsUrgent,
  additionalInstructions,
  setAdditionalInstructions,
  children,
}) => {
  const { data: customer } = useGetCustomerQuery();
  return (
    <>
      <form className="txu-form tw-mt-4 tw-flex tw-flex-col tw-gap-4">
        <div>
          <label>Email address(es) for returning the delivery *</label>
          <small>
            Separate multiple email addresses with commas <code>(,)</code>
          </small>
          <input
            data-testid="manual-translation-request-email"
            type="email"
            placeholder="Email..."
            value={email}
            onChange={(e): void => setEmail(e.target.value)}
            className="tw-w-full"
          />
        </div>
        <div>
          <input
            type="checkbox"
            id="manual-translation-request-urgent"
            data-testid="manual-translation-request-urgent"
            checked={isUrgent}
            onChange={(e): void => setIsUrgent(e.target.checked)}
          />
          <label htmlFor="manual-translation-request-urgent">
            {customer?.config.translation_request_urgent_message}
          </label>
        </div>
        <div>
          <label>Additional instructions</label>
          <textarea
            data-testid="manual-translation-request-additional-instructions"
            value={additionalInstructions}
            onChange={(e): void => setAdditionalInstructions(e.target.value)}
          />
        </div>
        {children}
      </form>
    </>
  );
};
