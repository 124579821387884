import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Prompt,
  PromptFormMode,
  Sentence,
  SentenceSectionInfo,
} from "../types";
import { Divider, Form, List, Modal } from "semantic-ui-react";
import { Text } from "../../../components/Text";
import { TemplateLabelChip } from "../../../vocabulary/templatesTabLabelSelector/TemplateLabelChip";
import {
  CopyAssistantContext,
  CopyAssistantDispatchContext,
} from "../CopyAssistantContext/CopyAssistantContext";
import { PromptForm } from "../PromptForm";
import { CopyAssistantActionType } from "../CopyAssistantContext/types";
import {
  setDjangoToastOpen,
  NotificationAppearance,
} from "../../../api/djangoToastSlice";
import {
  GptGenerationTaskQueue,
  createProductSpecificOverwrite,
  getModels,
  getPromptInputText,
  gptAddSentences,
} from "../../../api/gptApi";
import { RootState, store } from "../../../utils/store";
import { generateId } from "../../../utils/uuidUtils";
import { useSelector } from "react-redux";
import { SelectedPromptGroupPromptItem } from "./SelectedPromptGroupPromptItem";
import { selectedPromptGroupTestIds } from "../testUtils/testIdsSelectors";
import { debounce } from "../../../utils/debounce";
import { LanguageCode } from "../../../customers/customerlanguages";
import { LangStringObject } from "../../../vocabulary/LangString";
import {
  buildSentence,
  getCustomerEnglishLanguage,
  getSectionContext,
} from "../utils";
import { useGetCustomerQuery } from "../../../api/customerApi";
import { useGetDocumentStructures } from "../../../planner/document-structure/manage/customhooks";
import { reloadPreview } from "../../../legacy/t.product-detail";
import { GroupedGenerationButtons } from "./Components/Buttons";

type Props = {
  gptGenerationTaskQueue: GptGenerationTaskQueue;
  reFetchPrompts: () => Promise<void>;
  useChannelAsDefaultFallback: boolean;
};
export const SelectedPromptGroup: React.FC<Props> = ({
  gptGenerationTaskQueue,
  reFetchPrompts,
  useChannelAsDefaultFallback,
}) => {
  const {
    prompts: {
      list: prompts,
      availableTags: { customerOwned },
    },
    groups: { selected: selectedGroup },
    sentences: { isLoading: fetchingSentences, list: allSentences },
    inputData: { fetchNew: fetchNewInputData },
    productSpecificOverwrites: {
      list: allProductSpecificOverwrites,
      isLoading: productSpecificOverwriteLoading,
    },
    productId,
  } = useContext(CopyAssistantContext);
  const dispatch = useContext(CopyAssistantDispatchContext);
  const token = useSelector((state: RootState) => state.auth.token);
  const { data: customer } = useGetCustomerQuery();
  const { data: structures } = useGetDocumentStructures();
  const mounted = useRef(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [promptsInputData, setPromptsInputData] = useState<{
    [key: number]: string;
  }>({});
  const [inputDataFromFieldset, setInputDataFromFieldset] = useState<{
    [key: number]: string;
  }>({});
  const [collectingInputData, setCollectingInputData] = useState(false);
  const [selectedPromptToUpdate, setSelectedPromptToUpdate] = useState<
    Prompt
  >();
  const [openUpdatePromptModal, setOpenUpdatePromptModal] = useState(false);

  const [alteredInstructions, setAlteredInstructions] = useState<{
    [key: number]: string;
  }>({});

  const [imageUrls, setImageUrls] = useState<{ [key: number]: string }>({});

  const [alteredInputTexts, setAlteredInputTexts] = useState<{
    [key: number]: string;
  }>({});

  const visible = useMemo(() => !!selectedGroup, [selectedGroup]);

  const collectInputData = (): { [key: number]: string } => {
    const promptsInputData: { [key: number]: string } = {};
    (selectedGroup?.prompt_ids || []).forEach((id) => {
      const value =
        promptsInputData?.[id] ||
        inputDataFromFieldset?.[id] ||
        alteredInputTexts?.[id];

      promptsInputData[id] = value;
    });
    return promptsInputData;
  };
  const canGenerate = useMemo(() => {
    if (isGenerating) return false;

    const promptsInputData = collectInputData();
    return selectedGroup?.prompt_ids.every(
      (id) =>
        Object.keys(promptsInputData).includes(`${id}`) &&
        !!promptsInputData?.[id]
    );
  }, [
    alteredInputTexts,
    promptsInputData,
    inputDataFromFieldset,
    isGenerating,
  ]);

  useEffect(() => {
    mounted.current = true;
    return (): void => {
      mounted.current = false;
    };
  }, []);

  const groupsProductSpecificOverwrites = useMemo(() => {
    if (!selectedGroup) return [];
    return allProductSpecificOverwrites.filter(({ prompt_id }) =>
      selectedGroup.prompt_ids.includes(prompt_id)
    );
  }, [allProductSpecificOverwrites, selectedGroup]);

  const determineIfChannelIsInput = (prompt?: Prompt): boolean => {
    if (prompt) {
      if (prompt.fieldset_id) return false;
      if (prompt.channel_id) return true;
    }
    return useChannelAsDefaultFallback;
  };

  // This runs only once on initial render of the component
  const gptModels = useMemo(async () => {
    try {
      const models = await getModels(token);
      return models;
    } catch {
      return [];
    }
  }, [token]);

  const supportsImageProcessing = async (id: number): Promise<boolean> => {
    if (!selectedGroup) return false;
    const awaitedGptModels = await gptModels;

    if (!awaitedGptModels.length) return false;
    const prompt = prompts.find((p) => p.id == id);
    if (!prompt) return false;

    const model = awaitedGptModels.find(
      (model) => model.model_name === prompt?.model_name
    );
    const supportsImageProcessing = model?.supports_images;
    return supportsImageProcessing;
  };

  useEffect(() => {
    let canceled = false;
    if (selectedGroup && visible && fetchNewInputData)
      (async (): Promise<void> => {
        setCollectingInputData(true);
        const inputData: { [key: number]: string } = {};
        const promises: Promise<void>[] = selectedGroup.prompt_ids.map(
          async (promptId) => {
            const prompt = prompts.find(({ id }) => id === promptId);
            const shouldGetInputText = determineIfChannelIsInput(prompt);
            if (!shouldGetInputText) return;
            const { text } = await getPromptInputText(
              token,
              productId,
              promptId
            );
            if (mounted.current && text) {
              inputData[promptId] = text;
            }
          }
        );
        await Promise.all(promises);
        if (mounted.current && !canceled) {
          setCollectingInputData(false);
          setPromptsInputData(inputData);
          dispatch({
            type: CopyAssistantActionType.FETCH_NEW_PROMPT_INPUT,
            payload: false,
          });
        }
      })();

    return (): void => {
      canceled = true;
    };
  }, [selectedGroup, fetchNewInputData, visible]);

  useEffect(() => {
    if (groupsProductSpecificOverwrites.length && visible) {
      setAlteredInputTexts(
        groupsProductSpecificOverwrites.reduce(
          (acc, { prompt_id, input_data }) => {
            acc[prompt_id] = input_data;
            return acc;
          },
          {} as typeof alteredInputTexts
        )
      );
      setImageUrls(
        groupsProductSpecificOverwrites.reduce(
          (acc, { prompt_id, image_url }) => {
            acc[prompt_id] = image_url || "";
            return acc;
          },
          {} as typeof imageUrls
        )
      );
    }
  }, [groupsProductSpecificOverwrites, selectedGroup, visible]);

  useEffect(() => {
    if (!openUpdatePromptModal && mounted.current) {
      setSelectedPromptToUpdate(undefined);
    }
  }, [openUpdatePromptModal]);

  const handleInstructionRevert = (id: number): void => {
    const copy = { ...alteredInstructions };
    delete copy[id];
    setAlteredInstructions(copy);
  };

  const handleChangeInstruction = (id: number, value: string): void => {
    if (value === prompts.find((p) => p.id === id)?.instruction) {
      handleInstructionRevert(id);
    } else {
      setAlteredInstructions({ ...alteredInstructions, [id]: value });
    }
  };

  const handleInputTextRevert = (promptId: number): void => {
    createProductSpecificOverwrite(token, {
      product_id: productId,
      input_data: "",
      prompt_id: promptId,
      group_id: selectedGroup.id,
    }).then((overwrite) => {
      dispatch({
        type: CopyAssistantActionType.UPDATE_PRODUCT_SPECIFIC_OVERWRITE,
        payload: overwrite,
      });
    });
    const copy = { ...alteredInputTexts };
    delete copy[promptId];
    setAlteredInputTexts(copy);
  };

  const saveManualInputData = async (
    inputData: string,
    promptId: number
  ): Promise<void> => {
    await createProductSpecificOverwrite(token, {
      product_id: productId,
      input_data: inputData,
      prompt_id: promptId,
      group_id: selectedGroup.id,
    }).then((overwrite) => {
      dispatch({
        type: CopyAssistantActionType.UPDATE_PRODUCT_SPECIFIC_OVERWRITE,
        payload: overwrite,
      });
    });
  };

  const saveManualInputDataDebounce = useCallback(
    debounce(async (inputData: string, promptId: number): Promise<void> => {
      await saveManualInputData(inputData, promptId);
    }, 500),
    [selectedGroup]
  );

  const saveManualImageUrl = async (
    imageUrl: string,
    promptId: number
  ): Promise<void> => {
    await createProductSpecificOverwrite(token, {
      product_id: productId,
      image_url: imageUrl,
      prompt_id: promptId,
      group_id: selectedGroup.id,
    }).then((overwrite) => {
      dispatch({
        type: CopyAssistantActionType.UPDATE_PRODUCT_SPECIFIC_OVERWRITE,
        payload: overwrite,
      });
    });
  };

  const saveManualImageUrlDebounce = useCallback(
    debounce(async (imageUrl: string, promptId: number): Promise<void> => {
      await saveManualImageUrl(imageUrl, promptId);
    }, 500),
    [selectedGroup]
  );

  const handleChangeInputText = (id: number, value: string): void => {
    if (value === promptsInputData[id]) {
      handleInputTextRevert(id);
    } else {
      setAlteredInputTexts({ ...alteredInputTexts, [id]: value });
      saveManualInputDataDebounce(value, id);
    }
  };

  const handleImageUrlChange = (id: number, value: string): void => {
    setImageUrls({ ...imageUrls, [id]: value });
  };

  const handleCopyData = (value: string): void => {
    setAlteredInputTexts(
      selectedGroup.prompt_ids.reduce((acc, promptId) => {
        saveManualInputData(value, promptId);
        acc[promptId] = value;
        return acc;
      }, {} as typeof alteredInputTexts)
    );
  };

  const handleCopyImageUrl = (value: string): void => {
    setImageUrls(
      selectedGroup.prompt_ids.reduce((acc, promptId) => {
        saveManualImageUrl(value, promptId);
        acc[promptId] = value;
        return acc;
      }, {} as typeof imageUrls)
    );
  };

  const setLoadingState = (loading: boolean, id?: string): void => {
    setIsGenerating(loading);
    if (!loading) {
      // Hard reset all Sentences loading state
      dispatch({
        type: CopyAssistantActionType.RESET_ALL_SENTENCES_LOADING_STATES,
      });
      return;
    }
    if (id) {
      dispatch({
        type: CopyAssistantActionType.SET_SPECIFIC_SENTENCE_LOADING,
        payload: { id, isLoading: loading },
      });
    }

    dispatch({
      type: CopyAssistantActionType.SET_SENTENCE_LOADING,
      payload: { loading, promptIds: selectedGroup.prompt_ids },
    });
  };

  const handleGenerateManyAndAddToProduct = async (
    sentenceSectionInfoMap?: {
      [key: number]: SentenceSectionInfo;
    },
    autoTranslateAfterGeneration: boolean = false,
    mtConfigGroupId?: number
  ): Promise<void> => {
    const sentenceGroupId = generateId();
    const promptLanguages: { [key: number]: LanguageCode } = {};
    const inputDataMap = collectInputData();

    const reverseOrderOfPromptIds = [...selectedGroup.prompt_ids].reverse();

    const addedSentencesMap: { [key: number]: Sentence } = {};
    reverseOrderOfPromptIds.forEach((id) => {
      const prompt = prompts.find(({ id: promptId }) => promptId === id);

      const newSentence = buildSentence(
        [],
        {
          inputText: inputDataMap[id],
          instruction: alteredInstructions[id],
          promptId: id,
          groupId: selectedGroup.id,
        },
        customer,
        structures,
        prompt.language || getCustomerEnglishLanguage(customer).code,
        undefined,
        sentenceGroupId
      );
      newSentence.isLoading = true;
      newSentence.sectionInfo = sentenceSectionInfoMap[prompt.id];
      addedSentencesMap[prompt.id] = newSentence;
      dispatch({
        type: CopyAssistantActionType.ADD_SENTENCE,
        payload: newSentence,
      });
    });
    const generatedSentences: {
      promptId: number;
      langStringValues: LangStringObject[];
    }[] = [];

    const response = await gptGenerationTaskQueue
      .generateMany(
        productId,
        selectedGroup.prompt_ids,
        promptLanguages,
        alteredInstructions,
        inputDataMap,
        imageUrls
      )
      .catch(() => {
        Object.values(addedSentencesMap).forEach((sentence) => {
          dispatch({
            type: CopyAssistantActionType.REMOVE_SENTENCE,
            payload: sentence.id,
          });
        });
        return [] as Awaited<
          ReturnType<typeof gptGenerationTaskQueue.generateMany>
        >;
      });

    response.forEach((res) => {
      if (res.error) {
        store.dispatch(
          setDjangoToastOpen({
            appearance: NotificationAppearance.WARNING,
            content: `${
              prompts.find(({ id }) => res.prompt_id === id)?.name ||
              "One prompt"
            } failed to generate text.`,
            additionalContent: res.error,
          })
        );
        if (addedSentencesMap[res.prompt_id])
          dispatch({
            type: CopyAssistantActionType.REMOVE_SENTENCE,
            payload: addedSentencesMap[res.prompt_id].id,
          });
      } else {
        generatedSentences.push({
          promptId: res.prompt_id,
          langStringValues: res.sentences_batch.generated_texts,
        });
        const prompt = prompts.find(({ id }) => id === res.prompt_id);
        store.dispatch(
          setDjangoToastOpen({
            appearance: NotificationAppearance.INFO,
            content: `Generation completed in ${
              res.sentences_batch.seconds_spent_generating
            } seconds ${prompt ? "for " + prompt?.name : ""}`,
          })
        );
      }
    });
    const sectionInfoForOrdering: {
      [promptId: number]: { sentenceId: string; sectionName?: string };
    } = {};
    await Promise.all(
      generatedSentences.map(
        async ({ promptId, langStringValues }): Promise<void> => {
          const data = await gptAddSentences(
            productId,
            langStringValues[0],
            token,
            promptId
          );
          const sectionContext = getSectionContext(data, customer, structures);
          const newSentence = addedSentencesMap[promptId];
          dispatch({
            type: CopyAssistantActionType.UPDATE_SENTENCE,
            payload: {
              id: newSentence.id,
              toUpdate: {
                ...sectionContext,
                isLoading: false,
                value: langStringValues[0],
              },
            },
          });
          sectionInfoForOrdering[promptId] = {
            sentenceId: newSentence.id,
          };
          if (sectionContext?.sectionInfo) {
            sectionInfoForOrdering[promptId].sectionName =
              sectionContext.sectionInfo.documentSectionName;
          }
          if (autoTranslateAfterGeneration) {
            dispatch({
              type: CopyAssistantActionType.ADD_SENTENCE_AUTO_TRANSLATE_LIST,
              payload: {
                id: newSentence.id,
                mtConfigGroupId,
              },
            });
          }
        }
      )
    );
    const seenSectionNames: string[] = [];
    reverseOrderOfPromptIds.forEach((promptId) => {
      const orderingInfo = sectionInfoForOrdering[promptId];
      const sectionName = orderingInfo?.sectionName || "";
      if (!seenSectionNames.includes(sectionName)) {
        dispatch({
          type: CopyAssistantActionType.SET_SECTION_ACTIVE,
          payload: { id: orderingInfo.sentenceId },
        });
        seenSectionNames.push(sectionName);
      }
      if (sectionName) {
        dispatch({
          type: CopyAssistantActionType.ADD_ITEM_ORDER_SENTENCE_LIST,
          payload: sectionName,
        });
      }
    });
  };
  const onGenerateMany = async (
    autoTranslateAfterGeneration: boolean = false,
    mtConfigGroupId?: number
  ): Promise<void> => {
    setLoadingState(true);
    const sentenceSectionInfoMap: {
      [key: number]: SentenceSectionInfo;
    } = {};
    selectedGroup.prompt_ids.forEach((promptId) => {
      const prompt = prompts.find(({ id }) => id === promptId);
      if (!prompt) return;
      const foundSentence = allSentences.find(
        (sentence) =>
          sentence?.sectionInfo?.documentSectionId ===
          prompt?.document_section_id
      );
      setLoadingState(true, foundSentence?.id);
      if (foundSentence) {
        sentenceSectionInfoMap[prompt.id] = foundSentence.sectionInfo;
      }
    });
    await handleGenerateManyAndAddToProduct(
      sentenceSectionInfoMap,
      autoTranslateAfterGeneration,
      mtConfigGroupId
    ).finally(() => {
      setLoadingState(false);

      if (!autoTranslateAfterGeneration) {
        reloadPreview();
      }
      dispatch({
        type: CopyAssistantActionType.FETCH_NEW_PROMPT_INPUT,
        payload: true,
      });
    });
  };

  const collectStrings = (str: string, id: number): void => {
    const prompt = prompts.find((p) => p.id === id);
    const shouldUseChannelInput = determineIfChannelIsInput(prompt);
    if (shouldUseChannelInput) return;

    if (!str) {
      setInputDataFromFieldset((prev) => {
        const copy = { ...prev };
        delete copy[id];
        return copy;
      });
      return;
    }
    setInputDataFromFieldset((prev) => ({ ...prev, [id]: str }));
  };

  if (!visible) {
    return <></>;
  }
  return (
    <>
      <Form as="div" size="tiny">
        <Form.Field>
          {selectedGroup?.tags.map((tag) => (
            <TemplateLabelChip
              data-testid={selectedPromptGroupTestIds.tag(tag)}
              classNames="fitted-template-label"
              skipPredefinedClasses
              key={tag}
              content={tag}
              description={""}
              labelProps={{
                as: "span",
                size: "tiny",
              }}
            />
          ))}
        </Form.Field>
        <Form.Field>
          <Text color="grey" compact className="descriptive-helper-text">
            Prompts in running order
          </Text>
          <Divider fitted />

          <List ordered divided relaxed="very" size="small">
            {selectedGroup.prompt_ids.map((id: number) => (
              <SelectedPromptGroupPromptItem
                key={id}
                id={id}
                setOpenUpdatePromptModal={setOpenUpdatePromptModal}
                setSelectedPromptToUpdate={setSelectedPromptToUpdate}
                handleChangeInstruction={handleChangeInstruction}
                handleChangeInputText={handleChangeInputText}
                handleImageUrlChange={handleImageUrlChange}
                handleCopyData={handleCopyData}
                handleCopyImageUrl={handleCopyImageUrl}
                collectStrings={collectStrings}
                alteredInputText={alteredInputTexts[id]}
                alteredInstruction={alteredInstructions[id]}
                imageUrl={imageUrls[id]}
                supportsImageProcessing={supportsImageProcessing}
                saveManualImageUrl={saveManualImageUrlDebounce}
                inputData={promptsInputData[id]}
                loading={collectingInputData || productSpecificOverwriteLoading}
                useChannelAsDefaultFallback={useChannelAsDefaultFallback}
                gptGenerationTaskQueue={gptGenerationTaskQueue}
              />
            ))}
          </List>
        </Form.Field>
        <Form.Field>
          <div className="tw-sticky tw-bottom-2 tw-z-50 tw-flex tw-justify-center tw-gap-2">
            <GroupedGenerationButtons
              variant="primary"
              onGenerate={onGenerateMany}
              disabled={!canGenerate}
              loading={fetchingSentences}
              generateTestId={selectedPromptGroupTestIds.buttons.generate}
              generateAndTranslateTestId={
                selectedPromptGroupTestIds.buttons.generateAndTranslate
              }
            />
          </div>
        </Form.Field>
      </Form>
      <Modal
        open={openUpdatePromptModal && !!selectedPromptToUpdate}
        onClose={(): void => setOpenUpdatePromptModal(false)}
      >
        <PromptForm
          mode={PromptFormMode.UPDATE}
          setOpenModal={setOpenUpdatePromptModal}
          refreshPromptList={reFetchPrompts}
          availableTags={customerOwned}
          prompt={selectedPromptToUpdate}
        />
      </Modal>
    </>
  );
};
