import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import fromPairs from "lodash/fromPairs";
import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { Dimmer, Popup } from "semantic-ui-react";

import { useGetAuthQuery } from "../api/authApi";
import { useGetCustomerQuery } from "../api/customerApi";
import {
  requestProofreadingFiles,
  FileItem,
} from "../api/requestTranslationApi";
import { MultiFileDrop } from "../components/FileDrop";
import { Button, Icon } from "../components/tailwind";
import { Text } from "../components/Text";
import {
  LanguageCode,
  renderLanguageFromCode,
  SUICustomerLanguageDropdown,
} from "../customers/customerlanguages";
import { validateFileExtension } from "../utils/files";
import { RootState } from "../utils/store";
import { Spinner } from "../utils/Spinner";

const ALLOWED_EXTENSIONS = ["docx", "pptx", "xlsx", "pdf", "txt", "csv"];

export const ProofreadingRequest: React.FC = () => {
  const { data: customer, isLoading } = useGetCustomerQuery();
  const { data: auth, isLoading: isAuthLoading } = useGetAuthQuery();
  const token = useSelector((state: RootState) => state.auth.token);

  const [isRequesting, setIsRequesting] = useState(false);
  const [jobIds, setJobIds] = useState<{ [lang: string]: string }>({});
  const [email, setEmail] = useState("");
  const [isUrgent, setIsUrgent] = useState(false);
  const [additionalInstructions, setAdditionalInstructions] = useState("");
  const [deliverInOneFile, setDeliverInOneFile] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<FileItem[]>([]);

  const handleRequestProofreading = async (): Promise<void> => {
    setIsRequesting(true);
    try {
      if (deliverInOneFile) {
        const jobId = await requestProofreadingFiles(
          token,
          email,
          sourceFileItem,
          targetFileItems,
          isUrgent,
          additionalInstructions
        );
        setJobIds(
          fromPairs(
            targetFileItems.map((fileItem) => [fileItem.language, jobId])
          )
        );
      } else {
        const results = await Promise.all(
          targetFileItems.map((fileItem) => {
            return requestProofreadingFiles(
              token,
              email,
              sourceFileItem,
              [fileItem],
              isUrgent,
              additionalInstructions
            ).then((jobId) => {
              return { language: fileItem.language, jobId };
            });
          })
        );
        setJobIds((prevJobIds) => {
          const newJobIds = { ...prevJobIds };
          results.forEach(({ language, jobId }) => {
            newJobIds[language] = jobId;
          });
          return newJobIds;
        });
      }
    } finally {
      setIsRequesting(false);
    }
  };

  const resetAllStates = (): void => {
    setJobIds({});
    setIsUrgent(false);
    setAdditionalInstructions("");
    setDeliverInOneFile(false);
    setSelectedFiles([]);
    setIsRequesting(false);
  };

  useEffect(() => {
    if (isAuthLoading || !auth) return;
    setEmail(auth.user.email);
  }, [auth, isAuthLoading]);

  const sourceFileItem = useMemo((): FileItem | null => {
    return selectedFiles.find(({ isSource }) => isSource) || null;
  }, [selectedFiles]);

  const targetFileItems = useMemo((): FileItem[] => {
    return selectedFiles.filter(({ isSource }) => !isSource);
  }, [selectedFiles]);

  const isFormValid = useMemo((): boolean => {
    if (selectedFiles.filter(({ isSource }) => isSource).length > 1)
      return false; // multiple sources
    if (targetFileItems.length < 1) return false; // no target files selected
    if (selectedFiles.some(({ isInvalid }) => isInvalid)) return false; // some file is invalid
    if (selectedFiles.some(({ language }) => !language)) return false; // some file is missing language
    return true;
  }, [selectedFiles]);

  return (
    <>
      <div className="tw-grid tw-grid-cols-2 tw-gap-8">
        <div>
          <MultiFileDrop
            onAddFiles={(files): void => {
              const addedFiles: FileItem[] = files.map((file) => {
                return {
                  file,
                  isInvalid: !validateFileExtension(file, ALLOWED_EXTENSIONS),
                  isSource: false,
                  language: null,
                };
              });
              setSelectedFiles([...selectedFiles, ...addedFiles]);
            }}
            content={
              <>
                <Text>
                  Supported file types: PDF, Word (.docx), Excel (.xlsx), CSV
                </Text>
                <Text color="grey">
                  Also supported: Plain text (.txt), Powerpoint (.pptx)
                </Text>
                <small className="tw-text-gray-500">
                  <b>Note:</b> All words in the file are counted automatically
                  and form the basis of the assignment and billing.
                </small>
              </>
            }
          />
          <form className="txu-form tw-mt-8 tw-flex tw-flex-col tw-gap-4">
            <div>
              <label>Email address(es) for returning the delivery *</label>
              <small>
                Separate multiple email addresses with commas <code>(,)</code>
              </small>
              <input
                data-testid="proofreading-request-email"
                type="email"
                className="tw-w-full"
                placeholder="Email..."
                value={email}
                onChange={(e): void => setEmail(e.target.value)}
              />
            </div>
            <div>
              <input
                type="checkbox"
                id="proofread-request-urgent"
                data-testid="proofread-request-urgent"
                checked={isUrgent}
                onChange={(e): void => setIsUrgent(e.target.checked)}
              />
              <label htmlFor="proofread-request-urgent">
                {customer?.config.translation_request_urgent_message}
              </label>
            </div>
            <div>
              <label>Additional instructions</label>
              <textarea
                data-testid="proofreading-request-additional-instructions"
                value={additionalInstructions}
                onChange={(e): void =>
                  setAdditionalInstructions(e.target.value)
                }
              />
            </div>
            <div>
              <input
                type="checkbox"
                id="proofreading-request-deliver-in-one-file"
                data-testid="proofreading-request-deliver-in-one-file"
                checked={deliverInOneFile}
                onChange={(e): void => setDeliverInOneFile(e.target.checked)}
              />
              <label htmlFor="proofreading-request-deliver-in-one-file">
                Deliver in one file
              </label>
            </div>
          </form>
        </div>
        <div>
          <table className="txu-table txu-compact txu-clickable-rows tw-mb-4">
            <thead>
              <tr>
                <th>File</th>
                <th>Language</th>
                <th>
                  <Popup
                    content="Document is in source language (will not be proofread). Only one file can be marked as source."
                    position="top right"
                    trigger={
                      <span>
                        Source
                        <Icon
                          name="help"
                          className="tw-ml-2 tw-align-middle tw-text-xl"
                        />
                      </span>
                    }
                  />
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {selectedFiles.map((fileItem, ix) => (
                <tr
                  data-testid={"proofreading-request-table-row"}
                  key={fileItem.file.name}
                >
                  <td>{fileItem.file.name}</td>

                  <td className="tw-min-w-64">
                    {fileItem.isInvalid ? (
                      <Text color="red">Invalid file type.</Text>
                    ) : (
                      <SUICustomerLanguageDropdown
                        loading={isLoading}
                        clearable
                        data-testid="proofreading-request-select-language-dropdown"
                        fluid
                        placeholder="Select file language"
                        selection
                        value={fileItem.language}
                        onChange={(e, { value }): void => {
                          const langCode = value as LanguageCode;
                          const copy = cloneDeep(selectedFiles);
                          copy[ix].language = langCode;
                          setSelectedFiles(copy);
                        }}
                      />
                    )}
                  </td>

                  <td>
                    {!fileItem.isInvalid && (
                      <input
                        type="checkbox"
                        className="txu-input"
                        checked={fileItem.isSource}
                        onChange={(e): void => {
                          const copy = cloneDeep(selectedFiles);
                          copy[ix].isSource = e.target.checked;
                          setSelectedFiles(copy);
                        }}
                      />
                    )}
                  </td>

                  <td>
                    <Button
                      compact
                      size="sm"
                      content="Remove"
                      onClick={(): void => {
                        const copy = selectedFiles.filter((_, jx) => ix != jx);
                        setSelectedFiles(copy);
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Button
            data-testid="proofreading-request-send-request-button"
            variant="primary"
            content="Send request"
            className="tw-float-end"
            onClick={handleRequestProofreading}
            disabled={!isFormValid}
          />
        </div>
      </div>

      <Dimmer active={isRequesting || !isEmpty(jobIds)} page>
        {isRequesting ? (
          <Spinner
            inverted
            content="Sending for Proofreading..."
            data-testid="proofreading-request-loading"
            size="medium"
          />
        ) : (
          <>
            <Icon name="check" />
            <Text size="large">Sent for Proofreading!</Text>
            <Text size="small">
              You will receive an email at <b>{email}</b> when the proofreading
              is complete.
            </Text>
            <ul data-testid="proofreading-request-job-ids">
              {Object.entries(jobIds).map(([languageCode, jobId]) => {
                return (
                  <li key={languageCode} className="tw-my-2">
                    <strong>
                      {renderLanguageFromCode(
                        languageCode,
                        customer?.languages ?? []
                      )}
                    </strong>
                    : {jobId}
                  </li>
                );
              })}
            </ul>
            <Text size="small">
              Save the job ID(s) for use in correspondance with us.
              <br />
              Any related questions can be sent to{" "}
              <a
                href="mailto:translations@textual.se"
                style={{ color: "white" }}
              >
                translations@textual.se
              </a>
            </Text>
            <hr className="tw-my-4 tw-border-gray-600" />
            <Button
              data-testid="proofreading-request-done-button"
              content="Done"
              variant="primary"
              onClick={resetAllStates}
            />
          </>
        )}
      </Dimmer>
    </>
  );
};
