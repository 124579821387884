import React from "react";
import { useSelectedText } from "../utils/reactCustomHooks";
import { Button } from "../components/tailwind";

type Props = {
  value: string;
  onChange: (value: string) => void;
  toolbarClassName?: string;
  textareaClassName?: string;
  testId?: string;
  placeholder?: string;
};

export const NoTranslateTextarea: React.FC<Props> = ({
  value,
  onChange,
  children,
  toolbarClassName = "tw-flex tw-gap-4 tw-mb-2",
  textareaClassName,
  testId,
  placeholder,
}) => {
  const { selectedText, onSelect, selectionPosition } = useSelectedText(value);

  const handleMarkSelectionAsNoTranslate = (): void => {
    const selectedTextRemovedOldNoTranslate = selectedText
      .replaceAll("<notranslate>", "")
      .replaceAll("</notranslate>", "");
    const marked = `<notranslate>${selectedTextRemovedOldNoTranslate}</notranslate>`;
    const newSourceText =
      value.substring(0, selectionPosition[0]) +
      marked +
      value.substring(selectionPosition[1], value.length);
    onChange(newSourceText);
  };
  return (
    <>
      <div className={toolbarClassName}>
        <Button
          content="Mark selection as no-translate"
          compact
          size="sm"
          disabled={!selectedText}
          onClick={handleMarkSelectionAsNoTranslate}
        />
        {children}
      </div>
      <textarea
        onSelect={onSelect}
        className={`txu-textarea ${textareaClassName}`}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        data-testid={`${testId}`}
      />
    </>
  );
};
