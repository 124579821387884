import {
  AddSentenceToProductResponse,
  AddSentenceToProductTextBlockResponse,
} from "../../api/gptApi";
import { TextBlock } from "../../api/textBlockApi";
import { Customer } from "../../customers/Customer";
import { Language, LanguageCode } from "../../customers/customerlanguages";
import { DocumentStructure } from "../../planner/document-structure/types";
import { generateId } from "../../utils/uuidUtils";
import { LangStringObject } from "../../vocabulary/LangString";
import difference from "lodash/difference";
import take from "lodash/take";
import {
  HasTags,
  ObjectOf,
  PromptGroup,
  Prompt,
  PROMPT_WITH_NO_TAG_KEY,
  Sentence,
  SentenceSectionInfo,
  SentenceTemplateInfo,
  SentenceGenerationInfo,
} from "./types";

export function preventOverflowOnTextarea(
  textareaRefCurrent?: HTMLTextAreaElement,
  padding: number = 0
): void {
  if (!textareaRefCurrent) {
    return;
  }
  textareaRefCurrent.style.height = "auto"; // If there is no overflow set the height to auto to reset the height
  const hasOverflow =
    textareaRefCurrent.scrollHeight + padding > textareaRefCurrent.clientHeight;
  if (hasOverflow) {
    const diff =
      textareaRefCurrent.scrollHeight - textareaRefCurrent.clientHeight;
    textareaRefCurrent.style.height = `${
      textareaRefCurrent.clientHeight + diff + 10
    }px`;
  }
}

export function compareStrings(a: string, b: string): number {
  const A = a.toUpperCase();
  const B = b.toUpperCase();
  if (A < B) {
    return -1;
  }
  if (A > B) {
    return 1;
  }
  return 0;
}

export function orderObjectByKey<T>(
  unorderedObject: ObjectOf<T[]>
): ObjectOf<T[]> {
  return Object.keys(unorderedObject)
    .sort(compareStrings)
    .reduce((obj: ObjectOf<T[]>, key) => {
      obj[key] = unorderedObject[key];
      if (Array.isArray(obj[key])) {
        // Sort Template[] by name
        ((obj[key] as unknown) as { name: string }[]).sort((a, b) =>
          compareStrings(a.name, b.name)
        );
      }
      return obj;
    }, {});
}

function groupByTags<T extends HasTags>(items: T[]): ObjectOf<T[]> {
  const noTags: T[] = [];
  const obj: ObjectOf<T[]> = {};
  items.forEach((item) => {
    if (!item.tags?.length || item.tags.includes(PROMPT_WITH_NO_TAG_KEY)) {
      noTags.push({ ...item });
      return;
    }
    item.tags.forEach((tag) => {
      if (tag !== PROMPT_WITH_NO_TAG_KEY) {
        if (!obj[tag]) {
          obj[tag] = [];
        }
        obj[tag].push(item);
      }
    });
  });
  const ordered = orderObjectByKey<T>(obj);

  if (noTags.length) {
    return { [PROMPT_WITH_NO_TAG_KEY]: noTags, ...ordered };
  }
  return ordered;
}

export function groupPromptsByTags(prompts: Prompt[]): ObjectOf<Prompt[]> {
  return groupByTags<Prompt>(prompts);
}

export function groupPromptGroupsByTags(
  groups: PromptGroup[]
): ObjectOf<PromptGroup[]> {
  return groupByTags<PromptGroup>(groups);
}

export function search(item: string, searchString: string): boolean {
  return item.toLowerCase().search(searchString.toLowerCase()) !== -1;
}

export function isChatGpt(modelName: string): boolean {
  return (
    (modelName.startsWith("gpt-") ||
      modelName.startsWith("ft:gpt-") ||
      modelName.startsWith("o1-")) &&
    !modelName.includes("sw3")
  );
}

export function isClaude3(modelName: string): boolean {
  return modelName.startsWith("claude-3");
}

export function isGoogle(modelName: string): boolean {
  return modelName.startsWith("gemini") || modelName.startsWith("text-bison");
}

export function getSelectedPromptLanguage(
  customer: Customer,
  prompt: Prompt
): Language {
  return customer.languages.find(({ code }) => code === prompt.language);
}

export function getCustomerEnglishLanguage(customer: Customer): Language {
  const hasGBLanguage = customer.languages.some(({ code }) => code === "en_GB");
  const languageCode = hasGBLanguage ? "en_GB" : "en_US";
  return customer.languages.find(({ code }) => code === languageCode);
}

export function findCustomerLanguageFromLanguageCode(
  customer: Customer,
  languageCode: LanguageCode
): Language {
  return customer.languages.find(({ code }) => code === languageCode);
}

export const findLanguage = (
  prompt: Prompt | null,
  customer: Customer
): Language | null => {
  if (customer && prompt?.language) {
    return getSelectedPromptLanguage(customer, prompt);
  } else if (customer) {
    return getCustomerEnglishLanguage(customer);
  }
  return;
};

export const fixLongName = (name: string, maxLength: number = 50): string => {
  if (name?.length > maxLength) {
    return `${name.substring(0, maxLength)}...`;
  }
  return name;
};

export const findConnectedChannelsForSection = (
  sectionId: number,
  customer: Customer,
  structures: DocumentStructure[]
): { name: string; id: number }[] => {
  const channelIds: Set<number> = new Set();
  const foundStructures = structures.filter((structure) =>
    structure.sections.find((section) => section.id === sectionId)
  );
  if (foundStructures.length) {
    foundStructures.forEach((structure) => {
      const foundChannels = customer.channels.filter(
        (channel) => channel.document_structure_id === structure.id
      );
      foundChannels.forEach((channel) => {
        channelIds.add(channel.id);
      });
    });
  }
  const connectedChannels = customer.channels
    .filter(({ id }) => channelIds.has(id))
    .map(({ display_name, id }) => ({ name: display_name, id }));
  return [...connectedChannels];
};

export function validateImageUrl(url: string): Promise<boolean> {
  const img = new Image();
  img.src = url;
  return new Promise((resolve) => {
    img.onload = (): void => resolve(true);
    img.onerror = (): void => resolve(false);
  });
}

export const getSectionContext = (
  data: AddSentenceToProductResponse | AddSentenceToProductTextBlockResponse,
  customer: Customer,
  structures: DocumentStructure[]
): {
  templateInfo?: SentenceTemplateInfo;
  sectionInfo?: SentenceSectionInfo;
} => {
  const addedToTemplate = Object.keys(data).includes("template_id");

  if (addedToTemplate) {
    const {
      added_tag_id,
      template_id,
      template_display_name,
      lang_string_values,
    } = data as AddSentenceToProductResponse;
    return {
      templateInfo: {
        templateId: template_id,
        templateDisplayName: template_display_name,
        tagId: added_tag_id,
        langStringValues: lang_string_values,
      },
    };
  } else {
    const {
      text_block_id,
      document_section_id,
      document_section_name,
      lang_string_values,
    } = data as AddSentenceToProductTextBlockResponse;

    return {
      sectionInfo: {
        textBlockId: text_block_id,
        documentSectionId: document_section_id,
        documentSectionName: document_section_name,
        langStringValues: lang_string_values,
        connectedChannels: findConnectedChannelsForSection(
          document_section_id,
          customer,
          structures
        ),
      },
    };
  }
};

export const buildSentence = (
  langStringValues: LangStringObject[],
  generationInfo: SentenceGenerationInfo,
  customer: Customer,
  structures: DocumentStructure[],
  language: LanguageCode,
  data?: AddSentenceToProductResponse | AddSentenceToProductTextBlockResponse,
  sentenceGroupId?: string
): Sentence => {
  const sentenceContext = data
    ? getSectionContext(data, customer, structures)
    : {};
  return {
    id: generateId(),
    sentenceGroupId: sentenceGroupId,
    language: language,
    value: langStringValues[0],
    generationInfo: generationInfo,
    ...sentenceContext,
  };
};

/**
 * This function is used to load letter by letter in a stream of words when streaming the response from any GPT generation related endpoint.
 *
 * It creates a list of promises where every promise is one letter waiting to be loaded in (using setTimeout) and returns that list for the caller to wait for if needed.
 *
 * It also provides a function to hook in to GptGenerationTaskQueue generator functions and handle the letter queue
 */
export const updateSentenceValueFactory = (
  languageCode: LanguageCode,
  callback: (value: string) => void
): {
  awaitableTimeouts: Promise<unknown>[];
  updateResult: (
    result: { generated_texts: LangStringObject[]; streaming: boolean } | null
  ) => void;
} => {
  let oldValue = "";
  const promises: Promise<unknown>[] = [];
  const updateResult = (
    result: { generated_texts: LangStringObject[]; streaming: boolean } | null
  ): void => {
    if (!result || !result?.streaming) return;
    const generated_texts = result.generated_texts;
    if (!generated_texts) return;
    const newValue = generated_texts[0][languageCode];
    const lettersToAdd = newValue.slice(oldValue.length);
    let rememberOldValue = oldValue;
    oldValue = newValue;

    for (let i = 0; i < lettersToAdd.length; i++) {
      const promise = new Promise((resolve) => {
        setTimeout(() => {
          rememberOldValue += lettersToAdd[i];
          callback(rememberOldValue);
          resolve("done");
        }, 100);
      });
      promises.push(promise);
    }
  };

  return {
    awaitableTimeouts: promises,
    updateResult,
  };
};

/**
 * This function:
 * - Finds `PromptGroup` candidates that we might want to sort from using the provided list of `PromptGroups`
 * - Loops thru these candidates matching against the `textblocksToSort` to try to figure out which `PromptGroup` was used
 * - Uses the found `PromptGroup.prompt_ids` to sort the `textblocksToSort` to match the order in the `PromptGroup`
 *
 * It will always return a array. If the array is empty no sorting took place.
 */
const sortByPromptGroups = (
  textBlocksToSort: TextBlock[],
  allPromptGroups: PromptGroup[]
): TextBlock[] => {
  const sortingCandidates: { [len: number]: number[][] } = {}; // number array is array of prompt.ids

  // Fill the object with sorting candidates and skip groups where the prompt ids is longer than how many textblocks is present
  allPromptGroups.forEach((group) => {
    const promptLength = group.prompt_ids.length;
    if (promptLength <= textBlocksToSort.length) {
      if (!sortingCandidates[promptLength]) {
        sortingCandidates[promptLength] = [];
      }
      sortingCandidates[promptLength].push(group.prompt_ids);
    }
  });

  // No candidates found return early
  if (!Object.keys(sortingCandidates).length) {
    return [];
  }
  // only extract the needed data from the textblocks
  let textBlocksPromptIds = textBlocksToSort.map(
    (textblock) => textblock.prompt_id
  );
  let pointer = textBlocksToSort.length; // This is the lookup pointer used to index the sortingCandidates
  let pickedSorting: number[]; // If we found a suitable order this will be a array of prompt.ids
  while (pointer && !pickedSorting) {
    const candidates = sortingCandidates[pointer];
    textBlocksPromptIds = take(textBlocksPromptIds, pointer);
    if (candidates) {
      candidates.forEach((promptIds) => {
        // If the diff is empty we can assume that we found a match
        const diff = difference(promptIds, textBlocksPromptIds);
        if (!diff.length) {
          pickedSorting = promptIds;
        }
      });
    }
    pointer--;
  }

  if (!pickedSorting) return [];
  const sortedTextBlocks = pickedSorting.reduce<TextBlock[]>(
    (acc, promptId) => {
      const foundTextBlocks = textBlocksToSort.filter(
        (textBlock) => textBlock.prompt_id === promptId
      );
      if (foundTextBlocks.length) {
        foundTextBlocks.forEach((textblock) => {
          acc.push(textblock);
        });
      }
      return acc;
    },
    []
  );

  return sortedTextBlocks.concat(
    textBlocksToSort.filter(
      (textBlock) => !pickedSorting.includes(textBlock.prompt_id)
    )
  );
};

export const sortTextBlocks = (
  textBlocksToSort: TextBlock[],
  allPromptGroups: PromptGroup[] = [],
  selectedPromptGroup?: PromptGroup
): TextBlock[] => {
  if (!textBlocksToSort.length) return textBlocksToSort;
  if (selectedPromptGroup) {
    const sortedTextBlocks = sortByPromptGroups(textBlocksToSort, [
      selectedPromptGroup,
    ]); // If a prompt group is selected try to sort by that to minimize compute time
    if (sortedTextBlocks.length) return sortedTextBlocks;
  }

  if (allPromptGroups.length) {
    const sortedTextBlocks = sortByPromptGroups(
      textBlocksToSort,
      allPromptGroups
    );

    if (sortedTextBlocks.length) return sortedTextBlocks;
  }
  return textBlocksToSort;
};
