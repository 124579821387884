import React from "react";

import { FileDrop } from "../../components/FileDrop";
import { Text } from "../../components/Text";
import { LanguageCode } from "../../customers/customerlanguages";
import {
  ManualTranslationRequestForm,
  Props as ManualTranslationRequestFormProps,
} from "./ManualTranslationRequestForm";

export type Props = Omit<ManualTranslationRequestFormProps, "children"> & {
  selectedSourceLanguage: LanguageCode;
  selectedLanguages: LanguageCode[];
  selectedFile: File | null;
  setSelectedFile: (selectedFile: File | null) => void;
  isSelectedFileInvalid: boolean;
  deliverInOneFile: boolean;
  setDeliverInOneFile: (deliverInOneFile: boolean) => void;
};

export const ManualTranslationRequestFile: React.FC<Props> = ({
  selectedFile,
  setSelectedFile,
  isSelectedFileInvalid,
  deliverInOneFile,
  setDeliverInOneFile,
  ...formProps
}) => {
  return (
    <>
      <FileDrop
        selectedFile={selectedFile}
        isInvalid={isSelectedFileInvalid}
        onChange={(file): void => {
          setSelectedFile(file);
        }}
        contentUnselected={
          <>
            <Text>
              Supported file types: PDF, Word (.docx), Excel (.xlsx), CSV
            </Text>
            <Text color="grey">
              Also supported: Plain text (.txt), Powerpoint (.pptx)
            </Text>
            <small className="tw-text-gray-500">
              <b>Note:</b> All words in the file are counted automatically and
              form the basis of the assignment and billing.
            </small>
          </>
        }
      />
      <ManualTranslationRequestForm {...formProps}>
        <div>
          <input
            type="checkbox"
            id="manual-translation-request-deliver-in-one-file"
            data-testid="manual-translation-request-deliver-in-one-file"
            checked={deliverInOneFile}
            onChange={(e): void => {
              setDeliverInOneFile(e.target.checked);
            }}
          />
          <label htmlFor="manual-translation-request-deliver-in-one-file">
            Deliver in one file
          </label>
        </div>
      </ManualTranslationRequestForm>
    </>
  );
};
