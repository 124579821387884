import isNull from "lodash/isNull";
import isNil from "lodash/isNil";
import isEmpty from "lodash/isEmpty";
import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";

import { useGetCustomerQuery } from "../api/customerApi";
import {
  setDjangoToastOpen,
  NotificationAppearance,
} from "../api/djangoToastSlice";
import {
  MachineTranslateTaskQueue,
  DoneTranslateFileResponse,
} from "../api/machineTranslationApi";
import { FileDrop } from "../components/FileDrop";
import { Button, Icon } from "../components/tailwind";
import { Text } from "../components/Text";
import {
  LanguageCode,
  SUICustomerLanguageDropdown,
  renderLanguageFromCode,
} from "../customers/customerlanguages";
import {
  ProofreadingFile,
  ProofreadRequestModal,
  Props as ProofreadRequestModalProps,
} from "./ProofreadRequestModal";
import { validateFileExtension } from "../utils/files";
import { Spinner } from "../utils/Spinner";
import { RootState, store } from "../utils/store";
import { TranslateButton } from "../components/TranslateButton";
import { Popup } from "semantic-ui-react";
import { PreviouslyMachineTranslatedRail } from "./previously-machine-translated-resources/PreviouslyMachineTranslatedRail";
import {
  ProofreadingInfo,
  TranslationInfo,
} from "./previously-machine-translated-resources/PreviouslyMachineTranslatedRailList";
import { ProofreadStatusButton } from "./ProofreadStatusButton";
import { NOOP } from "..";
import { createHref } from "../utils/hrefUtils";

function downloadFile(fileName: string, fileUrl: string): void {
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = fileUrl;
  link.download = fileName; // suggests download (vs navigate) + file name for dialog; not very reliable
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const ALLOWED_EXTENSIONS = ["csv", "xlsx", "xls", "txt", "docx"];

export const MachineTranslateFile: React.FC = () => {
  const { data: customer } = useGetCustomerQuery();
  const token = useSelector((state: RootState) => state.auth.token);

  function renderLanguage(languageCode: string): string {
    return (
      renderLanguageFromCode(languageCode, customer?.languages ?? []) ?? ""
    );
  }

  const machineTranslateTaskQueue = useMemo(() => {
    if (token)
      return new MachineTranslateTaskQueue<DoneTranslateFileResponse>(
        token,
        3000
      );
  }, [token]);

  const [
    selectAllTargetLanguagesClicked,
    setSelectAllTargetLanguagesClicked,
  ] = useState(false);

  const [selectedFile, setSelectedFile] = useState<File>(null);

  const isSelectedFileInvalid = useMemo((): boolean => {
    if (isNull(selectedFile)) return false;
    return !validateFileExtension(selectedFile, ALLOWED_EXTENSIONS);
  }, [selectedFile]);

  const [pathToSourceFile, setPathToSourceFile] = useState<string>(null);
  const [sourceContext, setSourceContext] = useState<string>("");
  const [selectedSourceLanguage, setSelectedSourceLanguage] = useState<string>(
    null
  );
  const [selectedTargetLanguages, setSelectedTargetLanguages] = useState<
    string[]
  >([]);

  const [translateStatus, setTranslateStatus] = useState<{
    [language: string]: {
      loading: boolean;
      error: unknown;
      result: DoneTranslateFileResponse;
    };
  }>({});

  const [proofreadingRequests, setProofreadingRequests] = useState<
    {
      [key in LanguageCode]?: ProofreadingInfo;
    }
  >({});

  const [downloadAllLoading, setDownloadAllLoading] = useState<boolean>(false);

  const startTranslate = (configGroupId?: number): Promise<void> => {
    setRefreshTodaysTranslations(true);
    return machineTranslateTaskQueue
      .translateFile(
        selectedFile,
        selectedSourceLanguage,
        selectedTargetLanguages,
        sourceContext,
        configGroupId
      )
      .then((pathToSourceFile) => {
        setPathToSourceFile(pathToSourceFile);
        // Wait for all languages to be translated.
        const translateStatusObj: {
          [language: string]: {
            loading: boolean;
            error: unknown;
            result: DoneTranslateFileResponse;
          };
        } = {};
        selectedTargetLanguages.forEach((language) => {
          translateStatusObj[language] = {
            loading: true,
            error: null,
            result: null,
          };
        });
        setTranslateStatus(translateStatusObj);

        selectedTargetLanguages.forEach((language) => {
          machineTranslateTaskQueue
            .waitUntilTranslateLanguageIsDone(language)
            .then((result) => {
              setRefreshTodaysTranslations(true);
              setTranslateStatus((prev) => ({
                ...prev,
                [language]: {
                  loading: false,
                  error: null,
                  result: result,
                },
              }));
            })
            .catch(() => {
              setRefreshTodaysTranslations(true);
              setTranslateStatus((prev) => ({
                ...prev,
                [language]: {
                  loading: false,
                  error: "Translation failed",
                  result: null,
                },
              }));
            });
        });
      });
  };

  const allTranslateJobsDone = useMemo((): boolean => {
    return Object.values(translateStatus).every((status) => !status.loading);
  }, [translateStatus]);

  const allIsSelected = useMemo((): boolean => {
    if (!customer) return false;
    let allLanguagesLength = customer.languages.length;
    if (selectedSourceLanguage) {
      allLanguagesLength--;
    }
    return selectedTargetLanguages.length === allLanguagesLength;
  }, [selectedTargetLanguages, customer, selectedSourceLanguage]);

  const proofreadingModalButtonPropsForSpecificLanguage = (
    languageCode: LanguageCode
  ): ProofreadRequestModalProps["buttonProps"] => {
    return {
      disabled: proofreadingRequests[languageCode]?.done,
      variant: proofreadingRequests[languageCode]?.done
        ? "secondary-alt"
        : "primary",
      content: proofreadingRequests[languageCode]?.done
        ? "Sent for Proofreading"
        : "Send for Proofreading",
      "data-testid": `proofread-request-button-${languageCode}`,
    };
  };

  const languagesNotSendToProofreading = useMemo((): LanguageCode[] => {
    return selectedTargetLanguages.filter(
      (language) => !proofreadingRequests[language as LanguageCode]
    ) as LanguageCode[];
  }, [proofreadingRequests, selectedTargetLanguages]);

  const proofreadingModalButtonPropsAllLanguages = useMemo((): ProofreadRequestModalProps["buttonProps"] => {
    const allProofreadingRequestsSent =
      Object.keys(proofreadingRequests).length ===
      selectedTargetLanguages.length;

    const someLanguagesHaveProofreadingRequests = Object.values(
      proofreadingRequests
    ).some(Boolean);

    const common = {
      compact: true,
      size: "sm",
      className: "tw-float-end",
      disabled: !allTranslateJobsDone || allProofreadingRequestsSent,
    } as const;

    let content = "Send all for proofreading...";
    let variant: ProofreadRequestModalProps["buttonProps"]["variant"] =
      "primary";

    if (someLanguagesHaveProofreadingRequests) {
      content = "Send remaining languages for proofreading...";
    }

    if (allProofreadingRequestsSent) {
      content = "All translations sent for proofreading";
      variant = "secondary-alt";
    }

    return {
      ...common,
      content,
      variant,
    };
  }, [
    allTranslateJobsDone,
    proofreadingRequests,
    selectedTargetLanguages,
    languagesNotSendToProofreading,
  ]);

  const resetState = (): void => {
    setPathToSourceFile(null);
    setSourceContext("");
    setSelectedSourceLanguage(null);
    setSelectedTargetLanguages([]);
    setSelectedFile(null);
    setProofreadingRequests({});
    setTranslateStatus({});
  };

  const loadPreviousTranslation = (info: TranslationInfo): void => {
    resetState();
    setPathToSourceFile(info.source_file_path);
    setSourceContext(info.source_context);
    setSelectedSourceLanguage(info.source_language);
    setSelectedTargetLanguages(Object.keys(info.translations));
    setSelectedFile(new File([], info.file_name));
    for (const language of Object.keys(info.translations) as LanguageCode[]) {
      if (info.translations[language].proofreading_info) {
        setProofreadingRequests((prev) => ({
          ...prev,
          [language]: info.translations[language].proofreading_info,
        }));
      }
      setTranslateStatus((prev) => ({
        ...prev,
        [language]: {
          loading: false,
          error: null,
          result: {
            url: info.translations[language].download_link,
            target_file_path: info.translations[language].translated_file_path,
            original_target_file_name: info.translations[language].file_name,
          },
        },
      }));
    }
  };

  const [refreshTodaysTranslations, setRefreshTodaysTranslations] = useState(
    false
  );

  const onSendToProofreadingCallback = (
    jobIdsByLanguage?: { [key in LanguageCode]?: string }
  ): void => {
    setRefreshTodaysTranslations(true);
    if (jobIdsByLanguage) {
      Object.entries(jobIdsByLanguage).forEach(([language, jobId]) => {
        setProofreadingRequests((prev) => ({
          ...prev,
          [language]: {
            done: false,
            job_id: jobId,
          },
        }));
      });
    }
  };

  return (
    <>
      <PreviouslyMachineTranslatedRail
        loadPreviousTranslation={loadPreviousTranslation}
        refreshTodaysTranslations={refreshTodaysTranslations}
        setRefreshTodaysTranslations={setRefreshTodaysTranslations}
        type="file"
      />
      {Object.keys(translateStatus).length > 0 ? (
        // We have active translation tasks
        <>
          <div>
            <h3>Translating {selectedFile?.name}</h3>
          </div>
          <div
            data-testid="machine-translate-file-tasks"
            className="tw-my-8 tw-flex tw-w-full tw-flex-col tw-gap-4"
          >
            {Object.entries(translateStatus).map(([language, status]) => (
              <div
                key={language}
                className="tw-flex tw-items-center tw-border-b tw-pb-4"
              >
                <div className="tw-w-16">
                  {status.error ? (
                    <Icon name="warning" className="tw-text-red-600" />
                  ) : status.result ? (
                    <Icon name="check_circle" className="tw-text-green-600" />
                  ) : (
                    <Spinner size="small" align="left" />
                  )}
                </div>
                <div className="tw-w-64">{renderLanguage(language)}</div>
                <div className="tw-w-full">
                  {status.error ? (
                    <>
                      <Text compact color="black">
                        An error occurred while translating your file.
                      </Text>
                      <Text compact color="grey" size="small">
                        {status.error}
                      </Text>
                    </>
                  ) : status.result ? (
                    <div style={{ display: "flex", justifyContent: "right" }}>
                      {status.result.url ? (
                        <Button
                          variant="primary"
                          content="Download"
                          compact
                          size="sm"
                          data-testid="machine-translate-file-download-button"
                          onClick={(): void => {
                            const origName =
                              selectedFile?.name ?? pathToSourceFile;
                            const parts = origName.split(".");
                            const ext = parts.pop();
                            const newName =
                              parts.join(".") + "-" + language + "." + ext;
                            downloadFile(newName, status.result.url);
                          }}
                          className="tw-me-2"
                        />
                      ) : (
                        <a
                          className="txu-btn txu-sm txu-primary txu-compact tw-me-2"
                          href={
                            createHref("fileLogs", customer) +
                            "?search=" +
                            selectedFile?.name.split(".").slice(0, -1).join(".")
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          Search for file in file logs
                        </a>
                      )}
                      {proofreadingRequests[language as LanguageCode] && (
                        <ProofreadStatusButton
                          language={language as LanguageCode}
                          proofreadingInfo={
                            proofreadingRequests[language as LanguageCode]
                          }
                          viewingProofreading={[]}
                          setViewingProofreading={NOOP}
                        />
                      )}
                      {pathToSourceFile &&
                        !proofreadingRequests[language as LanguageCode] && (
                          <ProofreadRequestModal<ProofreadingFile>
                            proofreadingRequest={{
                              original: {
                                pathToFile: pathToSourceFile,
                                fileName: selectedFile?.name,
                                language: selectedSourceLanguage as LanguageCode,
                              },
                              requests: [
                                {
                                  pathToFile: status.result.target_file_path,
                                  fileName:
                                    status.result.original_target_file_name,
                                  language: language as LanguageCode,
                                },
                              ],
                            }}
                            buttonProps={proofreadingModalButtonPropsForSpecificLanguage(
                              language as LanguageCode
                            )}
                            onSendToProofreadingCallback={
                              onSendToProofreadingCallback
                            }
                            disclaimer={
                              <small className="tw-mt-2 tw-text-gray-500">
                                <b>Note:</b> All words in the file are counted
                                automatically and form the basis of the
                                assignment and billing.
                              </small>
                            }
                          />
                        )}
                    </div>
                  ) : (
                    <Text compact color="grey">
                      Translating...
                    </Text>
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className="tw-flex tw-w-full tw-justify-end tw-gap-2">
            {allTranslateJobsDone && (
              <>
                <ProofreadRequestModal<ProofreadingFile>
                  proofreadingRequest={{
                    original: {
                      pathToFile: pathToSourceFile,
                      fileName: selectedFile?.name,
                      language: selectedSourceLanguage as LanguageCode,
                    },
                    requests: selectedTargetLanguages
                      .filter(
                        (language) =>
                          translateStatus?.[language] &&
                          !translateStatus[language].error &&
                          languagesNotSendToProofreading.includes(
                            language as LanguageCode
                          )
                      )
                      .map((language) => ({
                        pathToFile:
                          translateStatus[language].result.target_file_path,
                        fileName:
                          translateStatus[language].result
                            .original_target_file_name,
                        language: language as LanguageCode,
                      })),
                  }}
                  buttonProps={proofreadingModalButtonPropsAllLanguages}
                  disclaimer={
                    <small className="tw-mt-2 tw-text-gray-500">
                      <b>Note:</b> All words in the file are counted
                      automatically and form the basis of the assignment and
                      billing.
                    </small>
                  }
                  allowSendAllLanguagesInOneRequest
                  onSendToProofreadingCallback={onSendToProofreadingCallback}
                />
                <Button
                  variant="primary"
                  content="Download all"
                  data-testid="machine-translate-file-download-all-button"
                  loading={downloadAllLoading}
                  onClick={async (): Promise<void> => {
                    const targetFilePaths = Object.values(translateStatus)
                      .filter((ts) => isNil(ts.error))
                      .map((ts) => ts.result.target_file_path);

                    setDownloadAllLoading(true);
                    try {
                      const zipUrl = await machineTranslateTaskQueue.downloadFiles(
                        pathToSourceFile,
                        targetFilePaths
                      );
                      const origName = selectedFile?.name ?? pathToSourceFile;
                      const parts = origName.split(".");
                      parts.pop(); // lose extension
                      const newName = parts.join(".") + "-translations.zip";
                      downloadFile(newName, zipUrl);
                    } catch {
                      store.dispatch(
                        setDjangoToastOpen({
                          content: "Error downloading files",
                          appearance: NotificationAppearance.ERROR,
                          additionalContent: null,
                        })
                      );
                    } finally {
                      setDownloadAllLoading(false);
                    }
                  }}
                />
              </>
            )}
            <Button
              variant="primary-alt"
              content={allTranslateJobsDone ? "Done" : "Cancel"}
              data-testid="machine-translate-file-done-button"
              onClick={(): void => {
                if (!allTranslateJobsDone) {
                  machineTranslateTaskQueue.cancelAll();
                }
                resetState();
                setRefreshTodaysTranslations(true);
              }}
            />
          </div>
        </>
      ) : (
        // File/language selection
        <div className="tw-grid tw-grid-cols-[1fr_auto_1fr] tw-gap-8">
          <div>
            <SUICustomerLanguageDropdown
              clearable
              data-testid="machine-translate-file-source-dropdown"
              fluid
              onChange={(_, { value }): void => {
                setSelectedSourceLanguage(value as string);
                setSelectedTargetLanguages((prev) => {
                  const langCodes = selectAllTargetLanguagesClicked
                    ? customer.languages.map((lang) => lang.code)
                    : prev;
                  if (langCodes.includes(value as LanguageCode)) {
                    return langCodes.filter((lang) => lang !== value);
                  }
                  return langCodes;
                });
              }}
              placeholder="Detect language"
              selection
              value={selectedSourceLanguage}
            />
            {!selectedSourceLanguage && (
              <div className="tw-mx-4 tw-my-2 tw-text-sm tw-text-gray-400">
                Glossaries are not used when source language is automatically
                detected.
              </div>
            )}
          </div>
          <div className="tw-pt-2">
            <Icon name="arrow_forward" />
          </div>
          <div className="tw-flex tw-flex-row tw-gap-2">
            <SUICustomerLanguageDropdown
              clearable
              data-testid="machine-translate-file-target-dropdown"
              error={!isNull(selectedFile) && isEmpty(selectedTargetLanguages)}
              fluid
              multiple
              onChange={(_, { value }): void => {
                setSelectedTargetLanguages(value as string[]);
                setSelectAllTargetLanguagesClicked(false);
              }}
              placeholder="Select language(s)"
              selection
              value={selectedTargetLanguages}
              className="tw-place-self-start"
            />
            <Button
              content="Select All"
              className="tw-place-self-start"
              compact
              size="sm"
              disabled={!customer || allIsSelected}
              variant="secondary-alt"
              onClick={(): void => {
                setSelectAllTargetLanguagesClicked(true);
                setSelectedTargetLanguages(
                  customer.languages
                    .filter(
                      (language) => language.code !== selectedSourceLanguage
                    )
                    .map((language) => language.code)
                );
              }}
            />
          </div>
          <div className="tw-col-span-3">
            <FileDrop
              selectedFile={selectedFile}
              isInvalid={isSelectedFileInvalid}
              onChange={(file): void => {
                setSelectedFile(file);
              }}
              contentUnselected={
                <>
                  <Text>
                    Supported file types: CSV (.csv), Excel (.xlsx), Plain text
                    (.txt), and Word (.docx)
                  </Text>
                </>
              }
              contentSelected={
                <TranslateButton
                  variant="primary"
                  content="Translate"
                  data-testid="machine-translate-file-translate-button"
                  disabled={
                    isEmpty(selectedTargetLanguages) || isSelectedFileInvalid
                  }
                  onClick={startTranslate}
                />
              }
            />
            <Text lessMargin>
              Additional context/Prompt instruction
              <span
                className="ui text small grey"
                style={{ marginInline: "5px" }}
              >
                (not translated)
              </span>
              <Popup
                wide
                trigger={
                  <Icon name="help" className="tw-align-middle tw-text-lg" />
                }
              >
                When translating with GPT models, this is sent in as an
                additional prompt instruction. When translating with DeepL, this
                is a feature to provide additional context. For MT services not
                mentioned, this does nothing.
              </Popup>
            </Text>
            <textarea
              className="txu-textarea"
              data-testid="machine-translate-context-textarea"
              onChange={(e): void => setSourceContext(e.target.value)}
              value={sourceContext}
            />
          </div>
        </div>
      )}
    </>
  );
};
